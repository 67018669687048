import styled from 'styled-components';

import { TableCell } from '@libs/components';

type ChildrenWrapperProps = {
  shouldHavePadding: boolean;
};

export const Container = styled.div`
  height: 75vh;

  overflow-x: hidden;

  ${({ theme }) => theme.media.laptop`
      height: unset;
      width: 80rem;
  `}
`;

export const BlueWrapper = styled.div`
  padding: 1.8rem 1.6rem 13.6rem;
  margin-bottom: 10.8rem;
  position: relative;

  background: linear-gradient(144.31deg, #3d62d7 -16.06%, #191c7a 121.84%);
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  ${({ theme }) => theme.media.laptop`
      width: 96%;
      margin: 0 auto 10.8rem;
  `}
`;

export const DescriptionGlass = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  border: 0.01rem solid ${({ theme }) => `${theme.colors.text.interactive}4D`};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  box-shadow: 0px 4px 134px 0px #ffffff40 inset;
  padding: 1.6rem;
`;

export const CustomImg = styled.img`
  height: 21rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -9rem;
  margin: 0 auto;
`;

export const ReferralProgramContainer = styled.div`
  width: 100%;
  padding: 1.6rem;

  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  box-shadow: ${({ theme }) => theme.shadows.lg};

  ${({ theme }) => theme.media.laptop`
      padding: 0.8rem 0.8rem 2.4rem;
  `}
`;

export const ReferralProgramTitleWrapper = styled.div`
  width: 100%;
  padding: 1.2rem 0;
  margin-bottom: 1.6rem;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.interactive.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
`;

export const AssetImg = styled.img`
  height: 6.4rem;
`;

export const ProgramDescWrapper = styled.div``;

export const AssetWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 4rem;

  gap: 1.6rem;
  .sectionTitle {
    margin-left: 1.6rem;
  }
`;

export const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  padding: ${({ shouldHavePadding }) =>
    shouldHavePadding ? '1.6rem' : '0rem'};

  text-align: start;
`;

export const CustomTableCell = styled(TableCell)`
  .cellDescription {
    align-items: end;
    flex-direction: row;
    gap: 0.8rem;
  }
`;

export const ProgramsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  ${({ theme }) => theme.media.tabletL`
    flex-direction: row;
  `}
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 1.2rem 1.8rem 1.2rem 3.2rem;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  box-shadow: ${({ theme }) => theme.shadows.lg};
`;

export const StepIndex = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: -2rem;

  background: linear-gradient(144.31deg, #3d62d7 -16.06%, #191c7a 121.84%);
  border-radius: ${({ theme }) => theme.borderRadius.highest};
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 2rem;
  div {
    background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  }
`;

export const QuestionsWrapper = styled.div``;

export const SeparatorLine = styled.div`
  margin: 1.2rem 0;
  height:0.05rem;
  background-color: ${({ theme }) => theme.colors.text.interactive};
  width: 100%;
`;
