import Lottie from 'lottie-react';

import { authLoadingAnimation } from '@libs/components';

import { LottieWrapper } from './styles';

export const PageLoader = () => {
  return (
    <LottieWrapper>
      <Lottie animationData={authLoadingAnimation} loop />
    </LottieWrapper>
  );
};

export default PageLoader;
