export const REFERRAL_PROGRAM_TEXTS = [
  {
    title: 'referral_invite_five_friends',
    amount: '5',
    amountDesc: 'referral_usdt',
    desc: 'referral_first_program_desc',
  },
  {
    title: 'referral_transactions',
    amount: '0.75%',
    amountDesc: '',
    desc: 'referral_second_program_desc',
    secondDesc: 'referral_second_program_second_desc',
  },
];

export const ENTERING_REFERAL_STEPS = [
  {
    desc: 'referral_steps_first_desc',
  },
  {
    desc: 'referral_steps_second_desc',
  },
  {
    desc: 'referral_steps_third_desc',
  },
];

export const REFERAL_FREQUENTLY_ASKED_QUESTIONS = [
  {
    id: '01',
    question: 'referral_faq_first_title',
    answer: ['referral_faq_first_desc'],
  },
  {
    id: '02',
    question: 'referral_faq_second_title',
    answer: ['referral_faq_second_desc'],
  },
  {
    id: '03',
    question: 'referral_faq_third_title',
    answer: ['referral_faq_third_desc'],
  },
  {
    id: '04',
    question: 'referral_faq_fourth_title',
    answer: ['referral_faq_fourth_desc'],
  },
  {
    id: '05',
    question: 'referral_faq_fifth_title',
    answer: ['referral_faq_fifth_desc'],
  },
  {
    id: '06',
    question: 'referral_faq_sixth_title',
    answer: ['referral_faq_sixth_desc'],
  },
];
