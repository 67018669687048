import { FC, PropsWithChildren } from 'react';

import { Typography } from '@libs/components';

import { ChildrenWrapper, Section } from './styles';

type InfoSectionProps = PropsWithChildren & {
  title: string;
  shouldHavePadding?: boolean;
};

export const InfoSection: FC<InfoSectionProps> = ({
  shouldHavePadding = true,
  title,
  children,
}) => {
  return (
    <Section>
      <Typography
        type="headline"
        fontFamily="primaryBold"
        className="sectionTitle"
      >
        {title}
      </Typography>
      <ChildrenWrapper shouldHavePadding={shouldHavePadding}>
        {children}
      </ChildrenWrapper>
    </Section>
  );
};
