import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

type ClaimBonusResponse = {
  claimBonus: boolean;
};

type ClaimBonusArgs = {
  assetCode: string;
};

export const claimBonusMutation: TypedDocumentNode<
  DefaultApiResponse<ClaimBonusResponse>,
  DefaultArg<ClaimBonusArgs>
> = gql`
  mutation ClaimBonus($record: ClaimBonusRecord!) {
    claimBonus(record: $record)
  }
`;
