import styled from 'styled-components';

import { InfoCircleIcon, SearchInput, Typography } from '@libs/components';

import { MegaPhoneIcon } from 'src/assets';

export const CustomDepositAddress = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tablet`
    display: block;

    height: initial;
  `}
`;
export const AddressDetailsContainerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
`;
export const AddressContianer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  margin: 1.7rem 2.2rem 2.7rem;

  ${({ theme }) => theme.media.tablet`
    margin: 1.7rem 3.7rem 2.7rem;
  `}
`;
export const QrViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0 2.4rem;
`;
type WalletAddressContProps = {
  isBnbMemo: boolean;
  bottomBorderShouldhave: boolean;
};
export const WalletAddressCont = styled.div<WalletAddressContProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 1.6rem 2.1rem 2.2rem;

  border-top: ${({ theme, isBnbMemo }) =>
    !isBnbMemo ? `1px solid ${theme.colors.borders.default}` : 'none'};
  border-bottom: ${({ bottomBorderShouldhave, theme }) =>
    bottomBorderShouldhave
      ? `1px solid ${theme.colors.borders.default}`
      : 'none'};
`;
export const QrContainer = styled.div`
  margin-top: 1.3rem;
  padding: 1rem;
  border: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;
export const WalletAddress = styled(Typography)`
  word-break: break-all;
`;
export const CustomAddressCont = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AssetSelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1.8rem 2.2rem 1.2rem;

  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);

  ${({ theme }) => theme.media.tablet`
    padding: 1.8rem 3.7rem 1.4rem;
  `}
`;
export const DepositInfoCont = styled.div`
  display: flex;
  align-items: center;
  height: 6.4rem;
  padding: 0 3rem 0 3.6rem;
`;

export const CustomInfoCircleIcon = styled(InfoCircleIcon)`
  margin-right: 1.5rem;
`;
export const CustomDisclaimerContainer = styled.div`
  padding: 1.6rem 2.2rem 3.5rem;
  ${({ theme }) => theme.media.tablet`
    margin: 1.6rem 3.7rem 3.5rem;
  `}
`;
export const CustomDisclaimer = styled.div`
  margin: 4.6rem 0 2.6rem;
`;
export const DisclaimerCont = styled.div`
  margin: 1.6rem 2.2rem;
  ${({ theme }) => theme.media.tablet`
    margin: 1.6rem 3.7rem;
  `}
`;
export const NetworkSelectContainer = styled.div`
  margin: 1.6rem 2.2rem;
  ${({ theme }) => theme.media.tablet`
    margin: 1.6rem 3.7rem;
  `}
`;
export const CustomNetworkImgContainer = styled.div`
  margin-right: 1.2rem;
`;
export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  height: 100%;
`;
export const ValueTypography = styled(Typography)`
  text-align: left;
`;
export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  margin-top: 0.1rem;
`;
export const CopyContainer = styled.div`
  margin-left: 0.5rem;
`;

export const CustomSearchInput = styled(SearchInput)`
  height: 5.4rem;

  background-color: ${({ theme }) => theme.colors.surface.default};
`;

export const LineBreaker = styled.div`
  height: 0.1rem;
  width: calc(100% + 4.8rem);

  margin: 1.2rem -2.2rem;

  background-color: ${({ theme }) => theme.colors.borders.default};

  ${({ theme }) => theme.media.tablet`
    width: calc(100% + 7.4rem);

    margin: 1.6rem -7.4rem;
  `}
`;

export const AddressDetailsWrapper = styled.div`
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  box-shadow: ${({ theme }) => theme.shadows.xl};
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6.8rem;
  padding: 0 1.8rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.borders.default};

  &:last-child {
    border-bottom: none;
  }
`;

export const OptionContainerRightCorner = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const CustomMegaPhoneIcon = styled(MegaPhoneIcon)`
  position: absolute;
  top: -3rem;
  left: calc(50% - 3rem);
  ${({ theme }) => theme.media.tablet`
      left: calc(50% - 4rem);
  `};
`;
