import styled from 'styled-components';

import { Button, InfoCircleIcon, Typography } from '@libs/components';

type VisibilityProps = {
  isOpen: boolean;
};

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  position: relative;

  ${({ theme }) => theme.media.laptop`
      flex: 1;
      max-width: 43rem;
  `}
`;

export const UsdtImg = styled.img`
  height: 4.1rem;
  width: 5.4rem;
  transform: scaleX(-1);
`;

export const BalanceFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const IconWrapper = styled.div<VisibilityProps>`
  height: 2.4rem;
  width: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.interactive};
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  background-color: ${({ theme }) => theme.colors.text.interactive};

  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.3s ease;
`;

export const BalanceGlass = styled.div`
  display: flex;
  padding: 0.8rem;

  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 0px 35px 0px rgba(255, 255, 255, 0.25) inset;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

export const BenefitAmountWrapper = styled.div`
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.4rem;
`;

export const SeparatorLine = styled.div`
  border: 0.01rem solid ${({ theme }) => theme.colors.text.interactive};
  margin: 0.1rem 0.4rem;
  opacity: 0.8;
`;

export const ClaimButton = styled(Button)`
  height: 6rem;
  position: relative;

  ${({ theme }) => theme.media.laptop`
       width: auto;
       max-width:43rem;
  `}
`;

export const GlassWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 10rem 1.6rem 1.6rem;
  position: relative;

  border: 0.01rem solid ${({ theme }) => `${theme.colors.text.interactive}4D`};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  box-shadow: 0px 4px 134px 0px #ffffff40 inset;
`;

export const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  ${({ theme }) => theme.media.mobileL`
      grid-column-gap: 1.6rem;
      grid-row-gap: 1.6rem;
  `}
`;

export const SummaryMainContainer = styled.div`
  display: none;

  flex-direction: column;
  gap: 1.6rem;

  width: calc(100% - 4.4rem);

  padding: 1rem;
  margin: -8rem auto 0;

  z-index: ${({ theme }) => theme.zIndex.medium};

  background-color: ${({ theme }) => theme.colors.surface.default};

  border-radius: ${({ theme }) => theme.borderRadius.high};

  box-shadow: ${({ theme }) => theme.shadows.lg};

  ${({ theme }) => theme.media.mobileL`
     padding: 2rem;
  `}

  ${({ theme }) => theme.media.laptop`
      display: flex;
      margin: unset;
      position: unset;
      width: 37rem;
  `}
`;

export const VerifiedUsersContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1.2rem 2.6rem;

  box-shadow: ${({ theme }) => theme.shadows.xl};

  border-radius: ${({ theme }) => theme.borderRadius.high};
  border: 0.01rem solid ${({ theme }) => theme.colors.interactive.brandColor};
`;

export const VerifiedUsersContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  min-height: 7.2rem;
`;

export const VerifiedUsersText = styled(Typography)`
  max-width: 14rem;
`;

export const CustomTooltipIcon = styled(InfoCircleIcon)`
  width: 2rem;
  height: 2rem;
`;

export const SummaryField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  padding: 1rem 0.8rem;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.mobileL`
       padding: 1.6rem 1.2rem;
  `}
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const CustomTypography = styled(Typography)`
  margin-right: 0.8rem;
`;

export const CustomInfoCircleIcon = styled(InfoCircleIcon)`
  height: 1.6rem;
  width: 1.6rem;
`;

export const ResultFeedbackWrapper = styled.div`
  ${({ theme }) => theme.media.tabletL`
      display: flex;
      justify-content: center;
      width: 50rem
  `}
`;

// ReferralConditions Styles

export const ReferralConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 0 2.4rem 2.4rem 2.4rem;

  width: 100%;

  ${({ theme }) => theme.media.laptop`
      max-width: 50rem;
  `};
`;

export const ImageWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoIcon = styled.img`
  width: 8rem;
  height: 8rem;
`;

export const VerifiedUsersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  border-radius: ${({ theme }) => theme.borderRadius.high};

  padding: 1.6rem 2rem;
`;

export const ConditionTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const CurrentPromotions = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  right: -0.1rem;
  top: -0.1rem;

  width: 12rem;
  height: 2.4rem;

  background-color: ${({ theme }) => theme.colors.interactive.brandColor};

  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.high};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.high};
`;
