import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

import { twoFaResponse } from './twoFa';

export type GoogleActiveTwoFaResponse = {
  turnOnGoogle2FA: DefaultApiResponse<twoFaResponse>;
};
export type GoogleDeactivateTwoFaResponse = {
  turnOffGoogle2FA: DefaultApiResponse<twoFaResponse>;
};

type GoogleToggleTwArgs = {
  smsTwoFactorCode?: string;
  twoFactorCode: string;
  emailTwoFactorCode?: string;
};

export const turnOnGoogle2FAMutation: TypedDocumentNode<
  GoogleActiveTwoFaResponse,
  DefaultArg<GoogleToggleTwArgs>
> = gql`
  mutation TurnOnGoogle2FA($record: ToggleGoogle2FARecord!) {
    turnOnGoogle2FA(record: $record) {
      accessToken
    }
  }
`;

export const turnOffGoogle2FAMutation: TypedDocumentNode<
  GoogleDeactivateTwoFaResponse,
  DefaultArg<GoogleToggleTwArgs>
> = gql`
  mutation TurnOffGoogle2FA($record: ToggleGoogle2FARecord!) {
    turnOffGoogle2FA(record: $record) {
      accessToken
    }
  }
`;
