import { gql } from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

// ----- Change phone -----

type ChangePhoneMutationArgs = {
  code: string;
  phone: string;
  formattedPhone: string;
};

export type ChangePhoneResponse = {
  changePhone: DefaultApiResponse<{ accessToken: string }>;
};

type ChangePhoneArgs = ChangePhoneMutationArgs;

export const changePhoneMutation: TypedDocumentNode<
  ChangePhoneResponse,
  DefaultArg<ChangePhoneArgs>
> = gql`
  mutation changePhone($record: UpdatePhoneRecord!) {
    changePhone(record: $record) {
      accessToken
    }
  }
`;

// Check phone and send code

export type CheckPhoneAndSendCodeResponse = {
  checkPhoneAndSendCode: DefaultApiResponse<boolean>;
};
type CheckPhoneAndSendCodeArgs = {
  destination: string;
};

export const checkPhoneAndSendCodeMutation: TypedDocumentNode<
  CheckPhoneAndSendCodeResponse,
  DefaultArg<CheckPhoneAndSendCodeArgs>
> = gql`
  mutation CheckPhoneAndSendCode($record: PhoneVerificationRecord!) {
    checkPhoneAndSendCode(record: $record)
  }
`;
