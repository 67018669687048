import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

type SendInvinationArgs = {
  email: string;
};

type SendInvinationRes = {
  sendReferralInvitation: boolean;
};

export const sendReferralInvitationMutation: TypedDocumentNode<
  DefaultApiResponse<SendInvinationRes>,
  DefaultArg<SendInvinationArgs>
> = gql`
  mutation SendReferralInvitation($record: SendReferralInvitationRecord!) {
    sendReferralInvitation(record: $record)
  }
`;
