import { useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

import { useTheme } from 'styled-components';

import { TransparentButton, Typography, useModal } from '@libs/components';
import { CurrencyType, ResultFeedbackType } from '@libs/types';
import { formatAmountWithSymbol, getEstimatedAmount } from '@libs/helpers';

import {
  claimBonusMutation,
  getBonusTotalQuery,
  GetBonusTotalRes,
} from 'src/gql';
import { BalanceTogglerIcon, TetherIcon } from 'src/assets';
import { useApiResponse, useTranslation } from 'src/hooks';

import ResultFeedback from '../result-feedback/ResultFeedback';

import { ReferralSummary } from './ReferralSummary';
import {
  BalanceContainer,
  BalanceFlexWrapper,
  BalanceGlass,
  BenefitAmountWrapper,
  ClaimButton,
  IconWrapper,
  ResultFeedbackWrapper,
  SeparatorLine,
  UsdtImg,
} from './styles';

export const ReferralBalance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { Dialog, openModal } = useModal();

  const bonousAmountRef = useRef<number>(0);

  const [isOpen, setIsOpen] = useState(true);

  const [resultFeedbackType, setResultFeedbackType] =
    useState<ResultFeedbackType>();

  const handleResultCallback = (type: ResultFeedbackType) => {
    openModal();
    setResultFeedbackType(type);
  };

  const handleVisibilityToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const bonusRes = useQuery(getBonusTotalQuery);

  const [claimBonus, claimBonusRes] = useMutation(claimBonusMutation);

  const handleTotalsSuccess = ({ getBonusTotals }: GetBonusTotalRes) => {
    bonousAmountRef.current = getBonusTotals.totalBonusBalance;
  };

  const { response } = useApiResponse(bonusRes, handleTotalsSuccess);

  const { loading, errorMessage } = useApiResponse(
    claimBonusRes,
    () => handleResultCallback(ResultFeedbackType.SUCCESS),
    () => handleResultCallback(ResultFeedbackType.ERROR)
  );

  const handleButtonClick = () => {
    claimBonus({
      variables: {
        record: {
          assetCode: CurrencyType.USDT,
        },
      },
      refetchQueries: [{ query: getBonusTotalQuery }],
    });
  };

  const bonusAmount =
    formatAmountWithSymbol({
      amount: bonousAmountRef.current,
      symbol: CurrencyType.USDT,
      reverse: true,
    }) || '';

  const description =
    resultFeedbackType === ResultFeedbackType.SUCCESS
      ? t('referral_success_claim_desc', {
          bonusAmount,
        })
      : t(errorMessage);

  const title =
    resultFeedbackType === ResultFeedbackType.SUCCESS
      ? t('referral_sucess_title')
      : undefined;

  const handleModalButtonClick = () => {
    navigate('/');
  };

  return (
    <>
      <Dialog title={t('referral_claim_title')}>
        {resultFeedbackType && (
          <ResultFeedbackWrapper>
            <ResultFeedback
              title={title}
              type={resultFeedbackType}
              description={description}
              onClick={handleModalButtonClick}
              buttonText={t('result_feedback_to_dashboard')}
            />
          </ResultFeedbackWrapper>
        )}
      </Dialog>
      <BalanceContainer>
        <UsdtImg src={TetherIcon} />
        <div>
          <Typography type="caption" color={colors.text.interactive}>
            {t('referral_total_benefit')}
          </Typography>
          <BalanceFlexWrapper>
            <div>
              <Typography
                type="largeTitle"
                color={colors.text.interactive}
                fontFamily="primaryBold"
              >
                {getEstimatedAmount(
                  response?.getBonusTotals?.totalBonusBalance
                )}{' '}
              </Typography>
              <Typography
                type="headline"
                color={colors.text.interactive}
                fontFamily="primaryBold"
              >
                {CurrencyType.USDT}
              </Typography>
            </div>
            <TransparentButton onClick={handleVisibilityToggle}>
              <IconWrapper isOpen={isOpen}>
                <BalanceTogglerIcon color={colors.interactive.brandColor} />
              </IconWrapper>
            </TransparentButton>
          </BalanceFlexWrapper>
        </div>
        {isOpen && (
          <BalanceGlass>
            <BenefitAmountWrapper>
              <Typography type="caption" color={colors.text.interactive}>
                {t('referral_benefits_by_invite')}
              </Typography>
              <div>
                <Typography
                  type="headline"
                  color={colors.text.interactive}
                  fontFamily="primaryBold"
                >
                  {getEstimatedAmount(
                    response?.getBonusTotals?.balanceByVerification
                  )}{' '}
                </Typography>
                <Typography type="caption" color={colors.text.interactive}>
                  {CurrencyType.USDT}
                </Typography>
              </div>
            </BenefitAmountWrapper>
            <SeparatorLine />
            <BenefitAmountWrapper>
              <Typography type="caption" color={colors.text.interactive}>
                {t('referral_benefits_by_trading')}
              </Typography>
              <div>
                <Typography
                  type="headline"
                  color={colors.text.interactive}
                  fontFamily="primaryBold"
                >
                  {getEstimatedAmount(
                    response?.getBonusTotals?.balanceByExchange
                  )}{' '}
                </Typography>
                <Typography type="caption" color={colors.text.interactive}>
                  {CurrencyType.USDT}
                </Typography>
              </div>
            </BenefitAmountWrapper>
          </BalanceGlass>
        )}

        <ClaimButton
          title={t('referral_claim')}
          buttonType="secondary"
          onClick={handleButtonClick}
          isLoading={loading}
          disabled={!response?.getBonusTotals.totalBonusBalance}
        />
      </BalanceContainer>
      <ReferralSummary response={response?.getBonusTotals} />
    </>
  );
};
