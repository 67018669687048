import styled from 'styled-components';

export const LottieWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40rem;

  div {
    height: 12rem;
    width: 12rem;
  }

  ${({ theme }) => theme.media.laptop`
       min-height: 66rem;
       div {
            height: 20rem;
            width: 20rem;
        }
  `}
`;
