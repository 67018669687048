import { TransactionType } from '@libs/types';

export const TransactionFiltersDefaultValue = {
  TYPE: [],
  ASSET: [],
  STATUS: [],
  MINDATE: undefined,
  MAXDATE: undefined,
};

export const TRANSACTION_LABELS: Record<string, string> = {
  [TransactionType.Buy]: 'dashboard_buy',
  [TransactionType.Sell]: 'dashboard_sell',
  [TransactionType.Deposit]: 'dashboard_deposit',
  [TransactionType.Withdrawal]: 'dashboard_withdraw',
  [TransactionType.BonusClaim]: 'dashboard_bonus_claim',
};

export { TransactionType };
