import { FC, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { JurisdictionsEnum } from '@libs/backend-common';
import { meQuery } from '@libs/graphql';
import { useApiResponse, useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';

import {
  AdvertismentNotifications,
  JurisdictionRenderer,
  MobileAppDisclaimer,
  //   RegisterSuccess,
} from 'src/components';
import { usePopUpModal, useTranslation, useModal } from 'src/hooks';
import { QR_MODAL_KEY } from 'src/constants';

import { CustomDisclaimer } from '../styles';

import LogInQrModal from './LogInQrModal';

const shouldOpenQrModal =
  !window.isFromMobileApp && !localStorage.getItem(QR_MODAL_KEY);

const handleModalClose = () => {
  localStorage.setItem(QR_MODAL_KEY, 'true');
};

type LoginPopUpsProps = {
  isAppDisclaimerVisible: boolean;
  isUnderConstruction?: boolean;
};

export const LayoutPopUps: FC<LoginPopUpsProps> = ({
  isAppDisclaimerVisible,
  isUnderConstruction,
}) => {
  const me = useQuery(meQuery);

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { width } = useDimensions();

  const isGreaterThanLaptop = width > screenSizes.laptop;

  const { response: meResponse } = useApiResponse(me);

  const {
    Dialog: QrModal,
    closeModal: closeQrModal,
    openModal: openQrModal,
  } = useModal(handleModalClose);

  const handleAdvertismentClose = useCallback(() => {
    if (shouldOpenQrModal) {
      openQrModal();
    }
  }, [openQrModal]);

  const {
    Dialog,
    openModal: openDialog,
    closeModal: closeDialog,
  } = useModal(handleAdvertismentClose);

  const {
    PopUpModal,
    openModal: openPopUp,
    closeModal: closePopUp,
  } = usePopUpModal();

  useEffect(() => {
    const isNewlyRegistered = searchParams.get('isNewlyRegistered');

    if (isNewlyRegistered) {
      searchParams.delete('isNewlyRegistered');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (!meResponse) {
      return;
    }

    const hasChosenNotificationOptions =
      typeof meResponse?.me.agreedToMarketingMessages === 'boolean';

    if (!hasChosenNotificationOptions) {
      if (isGreaterThanLaptop) {
        openPopUp();
      } else {
        openDialog();
      }
    }
    if (shouldOpenQrModal && isGreaterThanLaptop) {
      openQrModal();
    }
  }, [meResponse, openPopUp, openDialog, isGreaterThanLaptop, openQrModal]);

  return (
    <>
      <JurisdictionRenderer
        acceptedJurisdictions={[JurisdictionsEnum.GE, JurisdictionsEnum.EU]}
      >
        <Dialog title={t('registration_ads_title')}>
          <AdvertismentNotifications closeModal={closeDialog} />
        </Dialog>
        <PopUpModal>
          <AdvertismentNotifications closeModal={closePopUp} />
        </PopUpModal>

        {/* <RegisterSuccess
          openDialog={openDialog}
          isNewlyRegistered={isNewlyRegistered}
        /> */}
      </JurisdictionRenderer>

      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
        {isAppDisclaimerVisible && <MobileAppDisclaimer position="fixed" />}
        <QrModal>
          <LogInQrModal closeQrModal={closeQrModal} />
        </QrModal>
      </JurisdictionRenderer>

      {isUnderConstruction && (
        <CustomDisclaimer
          title="⚠️"
          boldDescription={t('platform_update_disclaimer')}
          hasIcon={false}
          isAppDisclaimerVisible={isAppDisclaimerVisible}
        />
      )}
    </>
  );
};
