import { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';

import { useApiResponse, useAuth } from '@libs/hooks';
import { meQuery } from '@libs/graphql';
import { TwoFaActions, TwoFactorAuthType } from '@libs/types';

import { useTranslation } from 'src/hooks';
import { TWO_FA_TITLE_KEYS } from 'src/types';

import { toggle2FaArgs } from 'src/gql';

import {
  ACTIVATE_OR_DEACTIVATE,
  LoginResponse,
  LOGIN_MUTATIONS,
  SEND_CODE_MUTATIONS,
  TurnOffResponse,
  TurnOnResponse,
  useActivationOptions,
} from './useActivationOptions';

export const useTwoFaActivation = (
  typeToActivate: TwoFactorAuthType,
  action: TwoFaActions,
  onFinish?: () => void
) => {
  const { t } = useTranslation();

  const [address, setAddress] = useState<string | undefined>('');

  const [hasEnteredAddress, setHasEnteredAddress] = useState(false);

  const { decodeToken } = useAuth();

  const [completedTwoFaSteps, setCompletedTwoFaSteps] = useState(0);
  const [completedOverallSteps, setCompletedOverallSteps] = useState(0);

  const {
    activatedTwoFaMethods,
    enteredAuths,
    handleLoginSuccess,
    activateCodes,
    handleSetCode,
    handleActivationSucess,
    handleDeactivationSucess,
  } = useActivationOptions();

  const hasCompletedAllSteps = useMemo(
    () => activatedTwoFaMethods.length === completedOverallSteps,
    [activatedTwoFaMethods.length, completedOverallSteps]
  );

  useEffect(() => {
    if (enteredAuths.includes(typeToActivate)) {
      setHasEnteredAddress(true);
      setAddress(undefined);
    }
  }, [enteredAuths, typeToActivate]);

  const currentMethod = useMemo(
    () => activatedTwoFaMethods[completedTwoFaSteps],
    [activatedTwoFaMethods, completedTwoFaSteps]
  );

  const refetchQueries = {
    refetchQueries: [meQuery],
  };

  const [sendMutation] = useMutation(
    SEND_CODE_MUTATIONS[hasCompletedAllSteps ? typeToActivate : currentMethod]
  );

  const [loginMutation, loginMutationResponse] = useMutation(
    LOGIN_MUTATIONS[hasCompletedAllSteps ? typeToActivate : currentMethod]
  );

  const [activateTwoFa, activateTwoFaRes] = useMutation(
    ACTIVATE_OR_DEACTIVATE[typeToActivate].activate,
    refetchQueries
  );

  const [deactivateTwoFa, deactivateTwoFaRes] = useMutation(
    ACTIVATE_OR_DEACTIVATE[typeToActivate].deactivate,
    refetchQueries
  );

  const hasReachedLastStep =
    completedTwoFaSteps + 1 >= activatedTwoFaMethods.length;

  const alreadyHasAddress = useMemo(
    () =>
      enteredAuths?.length ? enteredAuths.includes(typeToActivate) : false,
    [enteredAuths, typeToActivate]
  );

  useEffect(() => {
    const method =
      activatedTwoFaMethods?.[completedOverallSteps] || typeToActivate;

    if (method === TwoFactorAuthType.GOOGLE || hasCompletedAllSteps) {
      return;
    }

    sendMutation({
      variables: { record: {} },
    });
  }, [
    currentMethod,
    sendMutation,
    hasCompletedAllSteps,
    typeToActivate,
    activatedTwoFaMethods,
    completedOverallSteps,
    alreadyHasAddress,
  ]);

  const handleDeactivate = () => {
    const record = activateCodes as toggle2FaArgs;

    const { googleTwoFactorCode, emailTwoFactorCode, smsTwoFactorCode } =
      record;

    if (typeToActivate === TwoFactorAuthType.GOOGLE) {
      record.googleTwoFactorCode = undefined;
      record.twoFactorCode = googleTwoFactorCode || '--';
    } else if (typeToActivate === TwoFactorAuthType.SMS) {
      record.smsTwoFactorCode = undefined;
      record.twoFactorCode = smsTwoFactorCode || '--';
    } else if (typeToActivate === TwoFactorAuthType.EMAIL) {
      record.emailTwoFactorCode = undefined;
      record.twoFactorCode = emailTwoFactorCode || '--';
    }

    deactivateTwoFa({
      variables: { record },
    });
  };

  const shouldDeactivate =
    hasReachedLastStep && action === TwoFaActions.DEACTIVATE;

  const onLogin = (data: LoginResponse) => {
    handleLoginSuccess(data);
    if (shouldDeactivate) {
      handleDeactivate();
      return;
    }
    setCompletedOverallSteps((prev) => prev + 1);
    if (!hasReachedLastStep) {
      setCompletedTwoFaSteps((prev) => prev + 1);
    }
  };

  const onActivation = (data: TurnOnResponse) => {
    handleActivationSucess(data);
    onFinish?.();
  };

  const onDeactivation = (data: TurnOffResponse) => {
    handleDeactivationSucess(data);
    if (!hasCompletedAllSteps) {
      setCompletedOverallSteps((prev) => prev + 1);
    }
    onFinish?.();
  };

  const {
    loading: loginLoading,
    errorMessage: loginErrorMessage,
    clearErrorMessage: clearLoginError,
  } = useApiResponse(loginMutationResponse, onLogin);

  const {
    loading: toggleLoading,
    errorMessage: activateErrorMessage,
    clearErrorMessage: clearActivateError,
  } = useApiResponse(activateTwoFaRes, onActivation);

  const {
    loading: deactivationLoading,
    errorMessage: deactivateErrorMessage,
    clearErrorMessage: clearDeactivateError,
  } = useApiResponse(deactivateTwoFaRes, onDeactivation);

  const clearError = () => {
    clearDeactivateError();
    clearActivateError();
    clearLoginError();
  };

  useEffect(() => {
    if (completedOverallSteps) clearError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedOverallSteps]);

  const handleComplete = useCallback(
    (_: TwoFactorAuthType, val: string) => {
      loginMutation({
        variables: {
          record: {
            keepUser: false,
            twoFactorCode: val,
          },
        },
      });
      handleSetCode(currentMethod, val);
    },
    [loginMutation, handleSetCode, currentMethod]
  );

  const disclaimerText = useMemo(() => {
    if (!activatedTwoFaMethods || action === TwoFaActions.DEACTIVATE) return '';

    const hasMoreThanOneActivated = activatedTwoFaMethods.length > 1;

    const key = hasMoreThanOneActivated
      ? 'activate_2fa_multiple_steps_diclaimer'
      : 'activate_2fa_diclaimer';

    const value = {
      typeToActivate: t(TWO_FA_TITLE_KEYS[typeToActivate]),
      firstMethod: t(TWO_FA_TITLE_KEYS[activatedTwoFaMethods[0]]),
      secondMethod: t(TWO_FA_TITLE_KEYS?.[activatedTwoFaMethods[1]]),
    };

    return t(key, value);
  }, [activatedTwoFaMethods, typeToActivate, t, action]);

  const handleFinalAuth = useCallback(
    (_: TwoFactorAuthType, code: string) => {
      const key =
        typeToActivate === TwoFactorAuthType.EMAIL ? 'email' : 'phone';
      activateTwoFa({
        variables: {
          record: {
            [key]: address,

            twoFactorCode: code,
            ...activateCodes,
          },
        },
      });
    },
    [activateCodes, activateTwoFa, address, typeToActivate]
  );

  const errorMessage =
    activateErrorMessage || deactivateErrorMessage || loginErrorMessage;

  const resend = () => {
    sendMutation({
      variables: { record: {} },
    });

    if (errorMessage) {
      clearError();
    }
  };

  return {
    handleComplete,
    activatedTwoFaMethods,
    disclaimerText,
    hasEnteredAddress,
    setHasEnteredAddress,
    alreadyHasAddress,
    hasCompletedAllSteps,
    completedOverallSteps,
    activateCodes,
    handleFinalAuth,
    loading: toggleLoading || loginLoading || deactivationLoading,
    errorMessage,
    address,
    setAddress,
    resend,
  };
};
