import { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { useAuth, useTranslation } from 'src/hooks';
import { meQuery } from '@libs/graphql';
import { useMutation, useQuery } from '@apollo/client';

import { editEmailValidation } from 'src/validations';
import { useApiResponse, useCounter } from 'src/hooks';
import {
  editEmailMutation,
  EditEmailResponse,
  SendEmailCodeResponse,
  editEmailPreRequestMutation,
} from 'src/gql';
import { RESEND_CODE_TIME, ResultFeedbackType } from 'src/constants';

type UseEditEmailProps = {
  onSuccess?: () => void;
  onFormSubmit?: () => void;
  initialValue: string;
  showContent: () => void;
};
export const useEditEmail = ({
  onSuccess,
  onFormSubmit,
  initialValue,
  showContent,
}: UseEditEmailProps) => {
  const { data, loading } = useQuery(meQuery);
  const { response } = useApiResponse({ data, loading });
  const { countDown, counterInterval, isCounterRunning } =
    useCounter(RESEND_CODE_TIME);

  const { t } = useTranslation();
  const { login } = useAuth();
  const [emailToDisplay, setEmailToDisplay] = useState('');
  const [code, setCode] = useState('');
  const [feedbackType, setFeedbackType] = useState<
    ResultFeedbackType | undefined
  >();
  const [error, setError] = useState('');
  const [editEmail, editEmailRes] = useMutation(editEmailMutation);
  const [sendEmailCode, sendEmailPreRequestRes] = useMutation(
    editEmailPreRequestMutation
  );
  const handleFocus = () => {
    setError('');
  };
  const successEditEmail = (res: EditEmailResponse) => {
    onSuccess?.();
    if (res.changeEmail) {
      login(res.changeEmail.accessToken);
      setFeedbackType(ResultFeedbackType.SUCCESS);
    } else {
      setFeedbackType(ResultFeedbackType.ERROR);
    }
  };
  const {
    errorMessage: emailErrorMessage,
    clearErrorMessage: clearCodeError,
    loading: isEditingEmail,
  } = useApiResponse(editEmailRes, successEditEmail, showContent);

  const isNewEmail = response?.me.email !== initialValue;

  const {
    values,
    handleChange,
    handleSubmit,
    errors: formErrors,
    touched,
    setTouched,
    dirty,
  } = useFormik({
    initialValues: {
      email: isNewEmail ? initialValue || '' : '',
    },
    onSubmit: () => onFormSubmit?.(),

    validationSchema: editEmailValidation,
  });

  const handleEmailChange = (valCode: string) => {
    if (isEditingEmail) return;
    editEmail({
      variables: {
        record: {
          email: values.email,
          code: code || valCode,
        },
      },
      refetchQueries: [{ query: meQuery }],
    });
  };

  const isValid = dirty && !formErrors.email;

  const successEmailCodeSend = (res: SendEmailCodeResponse) => {
    if (!res?.editEmailPreRequest) return;
    counterInterval();
  };

  const { errorMessage: CodeErrorMessage, clearErrorMessage: clearPhoneError } =
    useApiResponse(sendEmailPreRequestRes, successEmailCodeSend);
  const handleBlur = () => {
    isValid && values.email ? null : setError(t('validation_email'));
  };
  const emailError = emailErrorMessage ? emailErrorMessage : '';

  const errorMessage = CodeErrorMessage ? CodeErrorMessage : emailError;

  const isSameEmail =
    response?.me.email?.toLowerCase() === values.email?.toLowerCase();

  const isButtonActive = isValid && !isSameEmail && !isCounterRunning;

  const resendHandler = () => {
    setEmailToDisplay(values.email);
    sendEmailCode({
      variables: {
        record: { email: values.email },
      },
    });
  };

  const sendCodeHandler = () => {
    if (isButtonActive) {
      resendHandler();
    }
  };

  useEffect(() => {
    if (isNewEmail) {
      resendHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearCode = () => {
    setCode('');
    clearPhoneError();
    clearCodeError();
  };

  useEffect(() => {
    if (values.email) {
      clearPhoneError();
      clearCodeError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.email]);

  return {
    feedbackType,
    setCode,
    clearCode,
    errorMessage,
    isSameEmail,
    formErrors,
    isButtonActive,
    countDown,
    emailToDisplay,
    isValid,
    handleEmailChange,
    resendHandler,
    sendCodeHandler,
    sendEmailCode,
    isCounterRunning,
    handleSubmit,
    values,
    handleChange,
    touched,
    code,
    setTouched,
    handleBlur,
    error,
    handleFocus,
  };
};
