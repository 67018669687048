import {
  ModalWithTwoFa,
  ResultFeedback,
  SectionDescription,
} from 'src/components';
import styled from 'styled-components';

export const CustomResultFeedback = styled(ResultFeedback)`
  ${({ theme }) => theme.media.tablet`
    min-width: 50rem;
  `};
`;

export const CustomModalWithTwoFa = styled(ModalWithTwoFa)`
  padding: 2.2rem;

  ${({ theme }) => theme.media.tablet`
    padding: 3.7rem;
  `};
`;

export const Form = styled.form`
  margin-top: 0.8rem;
  padding: 0 2.2rem 2.2rem;
  ${({ theme }) => theme.media.tablet`
    padding: 0 3.7rem 3.7rem;
    min-width: 46rem;
    
  `};
  ${({ theme }) => theme.media.laptop`
    max-width: 48rem;
  `};
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 4rem;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
`;
export const ActionWrapper = styled.div`
  margin-top: 2rem;
`;
export const CodeWrapper = styled.div`
  margin: 1.6rem 0 2.4rem;
`;
export const Description = styled(SectionDescription)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
`;
export const ErrorWrapper = styled.div`
  margin-top: 1.8rem;
  height: 2rem;
`;

export const CustomDescription = styled(Description)`
  width: 40rem;
`;

export const CheckSpamWrapper = styled.div`
  padding: 0.8rem 1.2rem;
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  border-radius: ${({ theme }) => theme.borderRadius.high};
  margin: 0 0 0.8rem 0;

  ${({ theme }) => theme.media.mobileM`
    padding: 1.6rem 1.2rem;
  `};

  ${({ theme }) => theme.media.tablet`
    padding: 0.8rem 1.2rem;
    margin: 1.2rem 0 2rem 0;
  `};
`;
