import { useFormik } from 'formik';
import { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';

import { RESEND_CODE_TIME } from '@libs/components';
import { sendEmailVerificationCodeMutation } from '@libs/graphql';

import {
  turnOffEmail2FAMutation,
  TurnOffEmail2FAResponse,
  turnOnEmail2FAMutation,
  TurnOnEmail2FAResponse,
} from 'src/gql';
import { useApiResponse, useAuth, useCounter } from 'src/hooks';
import { toggleEmailTwoFaValidation } from 'src/validations';

type EmailTwoFaInitialValues = {
  googleCode?: string;
  emailCode: string;
};

export const useEmailTwoFa = (isEmailEnabled = false) => {
  const { login } = useAuth();

  const [isEmailActivated, setIsEmailActivated] = useState(false);

  const { countDown, counterInterval, isCounterRunning } =
    useCounter(RESEND_CODE_TIME);

  const [sendEmail, sendEmailRes] = useMutation(
    sendEmailVerificationCodeMutation
  );

  const handleSendEmailCodeSuccess = useCallback(() => {
    counterInterval();
  }, [counterInterval]);
  const { errorMessage: sendEmailErrorMessage } = useApiResponse(
    sendEmailRes,
    handleSendEmailCodeSuccess
  );
  const handleEmailSend = () => {
    sendEmail({
      variables: {
        record: {},
      },
    });
  };

  const handleSuccessToggle = (
    res: TurnOnEmail2FAResponse | TurnOffEmail2FAResponse
  ) => {
    if ('turnOnEmail2FA' in res) {
      const token = res?.turnOnEmail2FA.accessToken;
      login(token);
    } else {
      const token = res?.turnOffEmailFA.accessToken;
      login(token);
    }

    setIsEmailActivated(true);
  };

  const [handleEmail2FaActivation, ActivationRes] = useMutation(
    turnOnEmail2FAMutation
  );
  const { loading: isActivating, errorMessage: ActivationErrorMessage } =
    useApiResponse(ActivationRes, handleSuccessToggle);

  const [handleEmail2FaDeactivation, DeactivationRes] = useMutation(
    turnOffEmail2FAMutation
  );
  const { loading: isDeactivating, errorMessage: DeactivationErrorMessage } =
    useApiResponse(DeactivationRes, handleSuccessToggle);

  const handleEmail2Fa = () => {
    if (values.emailCode) {
      const payload = {
        variables: {
          record: {
            googleTwoFactorCode: values.googleCode,
            emailCode: values.emailCode,
          },
        },
      };

      if (isEmailEnabled) {
        handleEmail2FaDeactivation(payload);
      } else {
        handleEmail2FaActivation(payload);
      }
    }
  };
  const {
    values,
    handleChange,
    touched,
    errors,
    isValid,
    dirty,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      emailCode: '',
      googleCode: undefined,
    } as EmailTwoFaInitialValues,
    onSubmit: () => {
      handleEmail2Fa();
    },
    validationSchema: toggleEmailTwoFaValidation,
  });

  return {
    values,
    touched,
    errors,
    isLoading: isActivating || isDeactivating,
    errorMessage:
      ActivationErrorMessage ||
      DeactivationErrorMessage ||
      sendEmailErrorMessage,
    isValid,
    isEmailActivated,
    dirty,
    countDown,
    handleChange,
    handleEmailSend,
    setFieldValue,
    handleSubmit,
    isCounterRunning,
  };
};
