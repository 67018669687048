import { FC, ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { useTheme } from 'styled-components';

import { TransparentButton, Typography } from '@libs/components';
import { getEstimatedAmount } from '@libs/helpers';
import { CurrencyType } from '@libs/types';

import { useApiResponse, useModal, useTranslation } from 'src/hooks';
import { BonusTotal, getReferralsExchangeSumQuery } from 'src/gql';

import {
  CurrentPromotions,
  CustomTooltipIcon,
  CustomTypography,
  SummaryContainer,
  SummaryField,
  SummaryMainContainer,
  TooltipWrapper,
  VerifiedUsersContainer,
  VerifiedUsersContent,
  VerifiedUsersText,
} from './styles';
import ReferralConditions from './ReferralConditions';

type InfoFieldProps = {
  title: number | string | ReactNode;
  desc: string;
};

type ReferralSummaryProps = {
  response?: BonusTotal | null;
  className?: string;
};

export const ReferralSummary: FC<ReferralSummaryProps> = ({
  response,
  className,
}) => {
  const { t } = useTranslation();

  const { colors } = useTheme();

  const referralExchangeSum = useQuery(getReferralsExchangeSumQuery);

  const { response: exchangeSum } = useApiResponse(referralExchangeSum);

  const totalBonusBalance = getEstimatedAmount(response?.totalBonusBalance);

  const balanceTextType =
    totalBonusBalance.length < 6 ? 'titleTwo' : 'headline';

  const verifiedUsersDuringPromotion =
    response?.verificationContributorUsers || '0';

  const { Dialog, openModal, closeModal } = useModal();

  return (
    <SummaryMainContainer className={className}>
      <Dialog
        headerBackground={colors.surface.default}
        shouldHideHeaderBorder
        title=""
      >
        <ReferralConditions
          verifiedUsersDuringPromotion={verifiedUsersDuringPromotion}
          closeModal={closeModal}
        />
      </Dialog>

      <VerifiedUsersContainer>
        <CurrentPromotions>
          <Typography
            fontFamily="primaryRegular"
            type="caption"
            color={colors.text.interactive}
          >
            {t('referral_current_promotion')}
          </Typography>
        </CurrentPromotions>
        <VerifiedUsersContent>
          <Typography fontFamily="primaryBold" type="titleTwo">
            {verifiedUsersDuringPromotion}
          </Typography>
          <VerifiedUsersText
            fontFamily="primaryRegular"
            type="bodyButton"
            color={colors.text.secondary}
          >
            {t('referral_verified_user')}
          </VerifiedUsersText>
        </VerifiedUsersContent>
        <TransparentButton onClick={openModal}>
          <CustomTooltipIcon color={colors.text.secondary} />
        </TransparentButton>
      </VerifiedUsersContainer>

      <SummaryContainer>
        <InfoField
          title={response?.totalRegisteredUsers ?? '0'}
          desc={t('referral_registered_users')}
        />
        <InfoField
          title={response?.totalVerifiedUsers ?? '0'}
          desc={t('referral_verified_users')}
        />
        <InfoField
          title={getEstimatedAmount(exchangeSum?.getReferralsExchangeSum)}
          desc={t('referral_trade_amount')}
        />
        <InfoField
          title={
            <TooltipWrapper>
              <CustomTypography type={balanceTextType} fontFamily="primaryBold">
                {totalBonusBalance}
              </CustomTypography>
              <Typography type="bodyButton" color={colors.text.secondary}>
                {CurrencyType.USDT}
              </Typography>
            </TooltipWrapper>
          }
          desc={t('referral_received_amount')}
        />
      </SummaryContainer>
    </SummaryMainContainer>
  );
};

const InfoField: FC<InfoFieldProps> = ({ desc, title }) => {
  const { colors } = useTheme();

  return (
    <SummaryField>
      {['string', 'number'].includes(typeof title) ? (
        <Typography type="titleTwo" fontFamily="primaryBold">
          {title}
        </Typography>
      ) : (
        title
      )}
      <Typography type="bodyButton" color={colors.text.secondary}>
        {desc}
      </Typography>
    </SummaryField>
  );
};
