import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs } from '@libs/components';

import {
  CashTransactionType,
  FeatureModuleEnum,
  JurisdictionsEnum,
} from '@libs/backend-common';

import {
  useDepositWithdrawCurrencySelection,
  useTransactionStepper,
  useTranslation,
  useWalletAssets,
} from 'src/hooks';
import {
  TransactionLayout,
  DepositCrypto,
  DepositFiat,
  FeatureRenderer,
  JurisdictionRenderer,
  PageContainer,
} from 'src/components';

import { CustomTabsCont } from './styles';

const DepositPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { nextStep, currentStep, prevStep, resetStepper } =
    useTransactionStepper();

  const { assets } = useWalletAssets();

  const walletInfo = assets.allAssets || [];

  const {
    activeTab,
    setActiveTab,
    defaultAsset,
    setDefaultAsset,
    isCryptoTabActive,
    tabItems: TRANSACTIONS_TAB_ITEMS,
  } = useDepositWithdrawCurrencySelection({
    resetStepper,
    assets: walletInfo,
    type: CashTransactionType.DEPOSIT,
  });

  const onBackButtonClick = () => {
    prevStep();
    setDefaultAsset(undefined);
    navigate('/deposit');
  };

  useEffect(() => {
    if (location.state?.shouldResetStep) {
      resetStepper();
    }
  }, [location.state, resetStepper]);

  return (
    <FeatureRenderer
      acceptedFeature={[
        FeatureModuleEnum.CARD_DEPOSIT,
        FeatureModuleEnum.CASH_DEPOSIT,
        FeatureModuleEnum.BANK_WIRE_DEPOSIT,
        FeatureModuleEnum.CRYPTO_DEPOSIT,
      ]}
      shouldNavigate={true}
      shouldCheckKyc={false}
    >
      <PageContainer>
        <TransactionLayout
          onGoBack={onBackButtonClick}
          headTitle={t('transaction_deposit')}
          isBackButtonVisible={currentStep > 0}
        >
          {activeTab && currentStep === 0 && (
            <JurisdictionRenderer
              acceptedJurisdictions={[
                JurisdictionsEnum.GE,
                JurisdictionsEnum.EU,
              ]}
            >
              <CustomTabsCont>
                <Tabs
                  activeTab={activeTab}
                  tabs={TRANSACTIONS_TAB_ITEMS}
                  handleTabClick={setActiveTab}
                />
              </CustomTabsCont>
            </JurisdictionRenderer>
          )}

          {!isCryptoTabActive && (
            <DepositFiat onClick={nextStep} currentStep={currentStep} />
          )}

          {isCryptoTabActive && (
            <DepositCrypto
              defaultAsset={defaultAsset}
              currentStep={currentStep}
              assets={assets.cryptoAssets}
              nextStep={nextStep}
              setActiveAsset={setDefaultAsset}
            />
          )}
        </TransactionLayout>
      </PageContainer>
    </FeatureRenderer>
  );
};

export default DepositPage;
