import { useMemo } from 'react';

import {
  TwoFactorAuth as NativeTwoFactorAuth,
  TwoFactorAuthProps as NativeTwoFactorAuthProps,
} from '@libs/components';
import { TwoFactorAuthType } from '@libs/types';
import { useTwoFaDetection } from '@libs/hooks';

import { useTranslation } from 'src/hooks';

type TwoFactorAuthProps = Omit<
  NativeTwoFactorAuthProps,
  'counterText' | 'sendCodeText' | 'warningText'
> & {
  phone?: string;
};
const TwoFactorAuth: React.FC<TwoFactorAuthProps> = ({
  type,
  phone,
  ...props
}) => {
  const { t } = useTranslation();
  const { decodedToken } = useTwoFaDetection();

  const twoFaData = useMemo(() => {
    if (type === TwoFactorAuthType.EMAIL) {
      return t('verify_email_address');
    } else if (type === TwoFactorAuthType.SMS) {
      return t('two_fa_sms_desc', {
        lastDigits: phone || decodedToken?.phone || '',
      });
    }
    return t('two_fa_google_desc');
  }, [decodedToken?.phone, phone, t, type]);

  return (
    <NativeTwoFactorAuth
      description={twoFaData}
      warningText={t('two_fa_check_spam')}
      counterText={t('register_resend_code')}
      sendCodeText={t('register_resend')}
      incorrectCodeErrMessage={t('two_fa_invalid_code_error')}
      type={type}
      {...props}
    />
  );
};

export default TwoFactorAuth;
