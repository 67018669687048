import Lottie from 'lottie-react';

import { useTheme } from 'styled-components';
import { Typography, ButtonType, emailSentAnimation } from '@libs/components';

import { useTranslation } from 'src/hooks';

import {
  AnimWrapper,
  Container,
  ResendContainer,
  ResendWrapper,
} from './styles';

export type VerifyEmailProps = {
  titleText?: string;
  secondaryText?: string;
  countDown?: number;
  isDisabled?: boolean;
  buttonText?: string;
  verifyEmailStatus?: boolean;
  testId?: string;
  description?: JSX.Element;
  handleClick: () => void;
  buttonType?: ButtonType;
};

const VerifyEmail: React.FC<VerifyEmailProps> = ({
  buttonText,
  isDisabled,
  description,
  countDown,
  secondaryText,
  verifyEmailStatus,
  testId = 'verifyEmailContainer',
  handleClick,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    !isDisabled && handleClick();
  };

  return (
    <Container data-cy={testId}>
      {Boolean(secondaryText) && (
        <Typography
          testId="verifyEmailTypo"
          fontSize={1.4}
          lineHeight={2.1}
          type="secondary"
          fontFamily="primary"
        >
          {secondaryText}
        </Typography>
      )}

      {description}

      <AnimWrapper>
        <Lottie loop={true} animationData={emailSentAnimation} />
      </AnimWrapper>

      {Boolean(buttonText) &&
        (isDisabled ? (
          <Typography type="bodyButton" fontFamily="primaryBold">
            {t('verify_email_resend_code_in')} {countDown}
          </Typography>
        ) : (
          <ResendWrapper>
            {!verifyEmailStatus && (
              <Typography type="bodyButton">
                {t('verify_email_resend')}
              </Typography>
            )}
            <ResendContainer onClick={onClick}>
              <Typography
                color={colors.interactive.brandColor}
                testId="verifyEmailBtn"
                type="bodyButton"
              >
                {buttonText}
              </Typography>
            </ResendContainer>
          </ResendWrapper>
        ))}
    </Container>
  );
};

export default VerifyEmail;
