import { Suspense, useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';

import { meQuery } from '@libs/graphql';
import { JurisdictionsEnum } from '@libs/backend-common';

import {
  DashboardNav,
  DashboardHeader,
  DashboardMobileNav,
  JurisdictionRenderer,
} from 'src/components';
import { TOKEN_KEY } from 'src/constants';
import { DashboardBlurImg } from 'src/assets';
import { setOnUnauthorized } from 'src/setup';
import { TicketDataProvider } from 'src/contexts';
import { getPlatformUnderConstructionStatusQuery } from 'src/gql';
import {
  useApiResponse,
  useAuth,
  useGetTicketLiveData,
  useRenderObjectByTheme,
  POP_UP_MODAL_PARENT_ID,
  useAppDisclaimer,
} from 'src/hooks';

import { LayoutPopUps } from './components/LayoutPopUps';

import {
  BlurImage,
  BlurImageRight,
  ContentContainer,
  ContentWrapper,
  DashboardContainer,
} from './styles';

const Dashboard = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const { data, loading } = useQuery(meQuery, { fetchPolicy: 'network-only' });

  const { response } = useApiResponse({ data, loading });

  const DashboardBlurImage = useRenderObjectByTheme(
    undefined,
    DashboardBlurImg
  );

  const onLogout = useCallback(() => {
    navigate('/login', {
      replace: true,
    });
  }, [navigate]);

  const { logout, checkTokenValidity } = useAuth(onLogout);

  const { getTicketLiveData, liveData, closeTicketLiveData } =
    useGetTicketLiveData();

  useEffect(() => {
    let source: EventSource;
    const token = localStorage.getItem(TOKEN_KEY);
    if (checkTokenValidity(token) && response) {
      source = getTicketLiveData(response.me.email);
    }
    return () => {
      if (source) {
        closeTicketLiveData(source);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    setOnUnauthorized(logout);

    return () => setOnUnauthorized(() => ({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [fetchStatus, { data: statusData }] = useLazyQuery(
    getPlatformUnderConstructionStatusQuery,
    { fetchPolicy: 'network-only' }
  );
  const { response: statusRes } = useApiResponse({
    data: statusData,
  });

  useEffect(() => {
    fetchStatus();
  }, [location.pathname, fetchStatus]);

  const isUnderConstruction = statusRes?.getPlatformUnderConstructionStatus;

  const { isAppDisclaimerVisible } = useAppDisclaimer();

  return (
    <TicketDataProvider value={liveData}>
      <div id={POP_UP_MODAL_PARENT_ID} />
      <LayoutPopUps
        isUnderConstruction={isUnderConstruction}
        isAppDisclaimerVisible={isAppDisclaimerVisible}
      />
      <DashboardContainer data-cy="dashboard">
        <DashboardNav
          testId="dashboardNav"
          isUnderConstruction={isUnderConstruction}
        />
        <ContentContainer
          isUnderConstruction={isUnderConstruction}
          isAppDisclaimerVisible={isAppDisclaimerVisible}
        >
          <DashboardHeader
            testId="dashboardHeader"
            isUnderConstruction={isUnderConstruction}
          />
          <ContentWrapper data-cy="dashboardContentWrapper">
            <JurisdictionRenderer
              acceptedJurisdictions={[JurisdictionsEnum.UA]}
            >
              <BlurImage src={DashboardBlurImage} />
              <BlurImageRight src={DashboardBlurImage} />
            </JurisdictionRenderer>

            {/* NOTE: Dashboard's contents render here*/}
            <Suspense fallback={<></>}>
              <Outlet />
            </Suspense>
          </ContentWrapper>
          <DashboardMobileNav testId="dashboardMobleNav" />
        </ContentContainer>
      </DashboardContainer>
    </TicketDataProvider>
  );
};

export default Dashboard;
