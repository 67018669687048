import { FC } from 'react';

import { useTheme } from 'styled-components';

import QRCode from 'react-qr-code';

import { APP_LINKS, CloseIcon, Typography } from '@libs/components';
import { redirectToAppDownload } from '@libs/helpers';

import {
  AppStoreGray,
  GooglePlayGray,
  MobileScreenForQrModal,
} from 'src/assets';
import { useModal, useTranslation } from 'src/hooks';
import { dashboardUrl, QR_MODAL_KEY } from 'src/constants';

import {
  BrandColorBox,
  ButtonsWrapper,
  ButtonsWrapperMobile,
  CloseIconWrapper,
  CustomButton,
  DisplayWrapper,
  DisplayWrapperMobile,
  MobileScreenImage,
  ModalContentWrapper,
  ModalWrapper,
  ParrentQrWrapper,
  QrWrapper,
  StoreIcon,
  TextWrapper,
} from '../styles';

const navigatorLink = dashboardUrl + '/app-links';

const handleAppNavigation = () => {
  redirectToAppDownload(APP_LINKS.appStore, APP_LINKS.googlePlay);
};

type LoginQrModalProps = {
  closeQrModal: () => void;
};

const LogInQrModal: FC<LoginQrModalProps> = ({ closeQrModal }) => {
  const { colors } = useTheme();

  const { t } = useTranslation();

  return (
    <ModalWrapper>
      <CloseIconWrapper onClick={closeQrModal}>
        <CloseIcon color={colors.text.default} />
      </CloseIconWrapper>
      <DisplayWrapper>
        <ButtonsWrapperMobile>
          <StoreIcon src={AppStoreGray} />
          <StoreIcon src={GooglePlayGray} />
        </ButtonsWrapperMobile>
      </DisplayWrapper>
      <MobileScreenImage src={MobileScreenForQrModal} />
      <DisplayWrapperMobile>
        <ModalContentWrapper>
          <ParrentQrWrapper>
            <QrWrapper>
              <QRCode value={navigatorLink} size={165} />
            </QrWrapper>
          </ParrentQrWrapper>
          <ButtonsWrapper>
            <StoreIcon src={AppStoreGray} />
            <StoreIcon src={GooglePlayGray} />
          </ButtonsWrapper>
        </ModalContentWrapper>
      </DisplayWrapperMobile>
      <BrandColorBox>
        <TextWrapper>
          <Typography
            color={colors.text.interactive}
            type="headline"
            fontFamily="primaryBold"
          >
            {t('download_bitnet_app')}
          </Typography>
          <DisplayWrapperMobile>
            <Typography color={colors.text.interactive} type="subHeadline">
              {t('scan_qr_code_title')}
            </Typography>
          </DisplayWrapperMobile>
          <DisplayWrapper>
            <Typography color={colors.text.interactive} type="subHeadline">
              {t('download_bitnet_application_for_devices')}
            </Typography>
            <CustomButton
              buttonType="secondary"
              title={t('common_download')}
              onClick={handleAppNavigation}
            />
            <div onClick={closeQrModal}>
              <Typography type="bodyButton" color={colors.text.interactive}>
                {t('go_to_main_page')}
              </Typography>
            </div>
          </DisplayWrapper>
        </TextWrapper>
      </BrandColorBox>
    </ModalWrapper>
  );
};

export default LogInQrModal;
