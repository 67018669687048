import styled from 'styled-components';

import { Button, Disclaimer, TransparentButton } from '@libs/components';
import {
  DASHBOARD_NAV_WIDTH,
  RequireKyc,
  DEFAULT_NAV_WITDTH_WITH_PADDING,
} from 'src/components';
import { DISCLAIMER_HEIGHT } from 'src/constants';
import { BrandColorModalFrame, BrandColorModalFrameMobile } from 'src/assets';

type ContentContainerProps = {
  isUnderConstruction?: boolean;
  isAppDisclaimerVisible?: boolean;
};

export const ContentContainer = styled.section<ContentContainerProps>`
  display: flex;
  flex-direction: column;
  bottom: ${({ isUnderConstruction, isAppDisclaimerVisible }) =>
    isAppDisclaimerVisible && isUnderConstruction
      ? `-${DISCLAIMER_HEIGHT * 2}rem`
      : isUnderConstruction || isAppDisclaimerVisible
      ? `-${DISCLAIMER_HEIGHT}rem`
      : 'initial'};
  position: relative;

  flex: 1;

  height: 100%;
`;

export const ContentWrapper = styled.div`
  flex: 1;

  min-height: calc(100% - 8.3rem);

  margin: 8.3rem 0 8rem;

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  max-width: 100vw;
  ${({ theme }) => {
    const color = theme.colors.surface
      .interactiveDefault as unknown as TemplateStringsArray;

    return theme.media.laptop`
    margin: 8.1rem 0 6rem ${
      DASHBOARD_NAV_WIDTH as unknown as TemplateStringsArray
    }rem;

    background-color: ${color};
    max-width: calc(100vw -  ${
      DEFAULT_NAV_WITDTH_WITH_PADDING as unknown as TemplateStringsArray
    }rem);
  `;
  }};
`;

export const BlurImage = styled.img`
  display: none;
  pointer-events: none;

  width: 58.5rem;
  top: calc(100vh - 58.5rem / 2);
  left: 0;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.low};
  opacity: 0.6;

  ${({ theme }) => theme.media.laptop`
    display: inline;
  `};
`;

export const BlurImageRight = styled(BlurImage)`
  left: unset;
  top: calc(-58.5rem / 2 + 8rem);
  right: calc(-58.5rem / 2 + 8rem);
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex: 1;

  min-height: 100%;
  height: -webkit-fill-available;
`;

type CustomDisclaimerProps = {
  isAppDisclaimerVisible?: boolean;
};

export const CustomDisclaimer = styled(Disclaimer)<CustomDisclaimerProps>`
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.highest};
  height: 100%;

  max-height: 6.2rem;

  border: none;
  border-radius: unset;

  position: fixed;
  top: ${({ isAppDisclaimerVisible }) =>
    isAppDisclaimerVisible ? `${DISCLAIMER_HEIGHT}rem` : '0'};

  background-color: ${({ theme }) => theme.colors.interactive.secondary};
  .disclaimer-title {
    padding: unset;
    padding-right: 1rem;
  }
  .disclaimer-desc {
    text-align: start;
  }
`;

export const DashboardContentContainer = styled.div`
  display: flex;
  flex: 1;

  width: 100%;

  ${({ theme }) => {
    return theme.media.laptop`  
    justify-content:center;
    `;
  }};
`;

export const JustifiedContent = styled.div`
  display: flex;

  width: 100%;
  height: 100%;
  max-width: 82rem;
  background-color: transparent;

  ${({ theme }) => theme.media.laptop`
    padding: 1.8rem 1rem 0;
    justify-content:center;
  `};

  ${({ theme }) => theme.media.laptopM`
    padding:0;
    padding-top: 1.8rem;
  `};
`;

export const MainContent = styled.div`
  width: 100%;
`;

export const BalanceWrapper = styled.div`
  width: 100%;
  padding: 2.2rem 2.2rem 4.9rem;
  background: ${({ theme }) => theme.colors.surface.gradient};

  ${({ theme }) => {
    const radius = theme.borderRadius
      .mediumHigh as unknown as TemplateStringsArray;
    return theme.media.laptop`
      margin-bottom: 1.7rem;
      padding: 2.6rem 2.9rem 2.7rem;
      border-radius: ${radius};
    `;
  }};
`;

export const NavigationItems = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.7rem;
  justify-content: space-between;

  ${({ theme }) => theme.media.laptop`
    display: none;
  `};
`;

export const NavigationItem = styled(TransparentButton)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 7rem;
  height: 4.8rem;

  margin-bottom: 0.5rem;

  border-radius: ${({ theme }) => theme.borderRadius.highL};
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.26);
  box-shadow: inset 0px -4px 14px rgba(255, 255, 255, 0.25);
`;

export const WalletWrapper = styled.div`
  width: 100%;

  margin-top: -3.2rem;
  ${({ theme }) => theme.media.laptop`
    margin-top:0;
  `};
`;

export const Ads = styled.div`
  display: none;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-color: ${({ theme }) => theme.colors.text.default};

  ${({ theme }) => {
    return theme.media.laptopL`
      display:flex;
      width: 29.2rem;
      height: 34.8rem;
      margin-left: 2.5rem;
  `;
  }};
`;

export const MobileAds = styled.div`
  display: flex;
  height: 17.2rem;
  margin: 2.7rem 1.4rem 0;
  background-color: ${({ theme }) => theme.colors.text.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  ${({ theme }) => {
    return theme.media.laptop`
      margin: 2.7rem 0 0;
    `;
  }};

  ${({ theme }) => {
    return theme.media.laptopL`
      display:none;
    `;
  }};
`;

export const CustomKYC = styled(RequireKyc)`
  margin: 1.2rem 1.6rem;
  box-shadow: ${({ theme }) => theme.shadows.xs};
  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tablet`
    margin: 0 0 1.7rem;
  `}
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  padding-right: unset;
  justify-content: space-between;
  position: relative;
  ${({ theme }) => theme.media.tablet`
    flex-direction: row;
    padding-top: 5rem;
    align-items: unset;
    padding-right: 3rem;
  `}
`;

export const MobileScreenImage = styled.img`
  width: 22rem;
  height: 48.4rem;
  margin-left: unset;

  ${({ theme }) => theme.media.tablet`
   width: 22rem;
   height: 48.4rem;
   margin-left: 6rem;
  `}
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

export const QrWrapper = styled.div`
  width: 18rem;
  height: 18rem;
  background-color: ${({ theme }) => theme.colors.text.interactive};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 108px 30px 0px rgba(0, 0, 0, 0),
    0px 69px 28px 0px rgba(0, 0, 0, 0.03), 0px 39px 23px 0px rgba(0, 0, 0, 0.11),
    0px 17px 17px 0px rgba(0, 0, 0, 0.19), 0px 4px 9px 0px rgba(0, 0, 0, 0.22);
`;

export const ParrentQrWrapper = styled.div`
  width: 20rem;
  height: 20rem;
  background-color: ${({ theme }) => theme.colors.interactive.brandColor};
  border-radius: ${({ theme }) => theme.borderRadius.high};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: center;
`;

export const ButtonsWrapperMobile = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StoreIcon = styled.img`
  width: 13.9rem;
  height: 4.2rem;
`;

export const BrandColorBox = styled.div`
  width: 100%;
  position: fixed;
  height: 31rem;
  z-index: ${({ theme }) => theme.zIndex.high};
  bottom: -30px;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.highM};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.highM};
  rotate: -2.8deg;
  padding: 0 1rem;

  display: flex;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';

    top: -18px;
    left: -4px;
    right: -3px;
    position: absolute;
    height: 31rem;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.highM};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.highM};
    background-image: url(${BrandColorModalFrameMobile});
    opacity: 0.9;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    rotate: 2.8deg;
  }
  ${({ theme }) => {
    return theme.media.tablet`
         height: 17rem;

         &::before {
           background-image: url(${
             BrandColorModalFrame as unknown as TemplateStringsArray
           });
            height: 17rem;
            top: -19px;
            left: 0;
              }
            `;
  }}
`;

export const TextWrapper = styled.div`
  rotate: 2.8deg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 1.3rem;
  right: 1.6rem;

  width: 3.2rem;
  height: 3.2rem;

  border: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  border-radius: ${({ theme }) => theme.borderRadius.highest};

  cursor: pointer;

  svg {
    height: initial;
    width: initial;
  }
`;

export const DisplayWrapperMobile = styled.div`
  display: none;
  ${({ theme }) => theme.media.tablet`
     display: block;
  `}
`;

export const DisplayWrapper = styled.div`
  display: block;
  margin-bottom: 2rem;
  ${({ theme }) => theme.media.tablet`
     display: none;
  `}
`;

export const CustomButton = styled(Button)`
  box-shadow: 0px 148px 41px 0px rgba(0, 0, 0, 0),
    0px 95px 38px 0px rgba(0, 0, 0, 0.03), 0px 53px 32px 0px rgba(0, 0, 0, 0.11),
    0px 24px 24px 0px rgba(0, 0, 0, 0.19), 0px 6px 13px 0px rgba(0, 0, 0, 0.22);

  margin: 2rem 0;
`;
