import { useCallback, useRef, useState } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { useNetworkIcons, SelectData } from '@libs/components';
import { NetworksType } from '@libs/types';

import {
  getSupportedNetworksQuery,
  GetSupportedNetworksResponse,
  GetSupportedNetworksType,
} from 'src/gql';

import { useApiResponse } from 'src/hooks';

export type NetworkArray = SelectData<string> & {
  id: number;
  code: string;
};

export const useNetworkSelect = (
  setNetworkDefault?: (item: string | number) => void,
  selectedNetworkValue?: string
) => {
  const isDefaultNetworkSet = useRef<string>();
  const [networksForSelect, setNetworksForSelect] = useState<NetworkArray[]>(
    []
  );
  const [networksArray, setNetworksArray] = useState<
    GetSupportedNetworksType[]
  >([]);
  const NetworkIcons = useNetworkIcons('3.5rem');
  const generateNetworkSelectObj = useCallback(
    (data: GetSupportedNetworksType[]) => {
      if (data) {
        const networkArray: NetworkArray[] = data.map((res) => {
          return {
            value: res.fireblocksId,
            label: res.name,
            disabled: !res.isEnabled,
            icon: NetworkIcons[res.fireblocksId as NetworksType],
            code: res.code,
            id: res.id,
          };
        });
        setNetworksForSelect(networkArray);

        const firstElOfNetworkArr = networkArray[0];

        if (setNetworkDefault && !selectedNetworkValue) {
          setNetworkDefault(firstElOfNetworkArr?.value);
          isDefaultNetworkSet.current = firstElOfNetworkArr?.label;
        }
      }
    },
    [NetworkIcons, selectedNetworkValue, setNetworkDefault]
  );
  const handleGetSupportedNetworksSuccess = useCallback(
    (data: GetSupportedNetworksResponse) => {
      setNetworksArray(data.getSupportedNetworks);
      generateNetworkSelectObj(data.getSupportedNetworks);
    },
    [generateNetworkSelectObj]
  );
  const [
    handleGetSupportedNetworks,
    { data: getSupportedNetworksData, error: getSupportedNetworksError },
  ] = useLazyQuery(getSupportedNetworksQuery);

  const { errors: confirmCreateAssetForUserErrorMessage } = useApiResponse<
    GetSupportedNetworksResponse,
    ApolloError
  >(
    { data: getSupportedNetworksData, errors: getSupportedNetworksError },
    handleGetSupportedNetworksSuccess
  );

  const getSupportedNetworks = (assetCode: string | number) => {
    handleGetSupportedNetworks({
      variables: {
        record: {
          assetCode,
        },
      },
      fetchPolicy: 'network-only',
    });
  };

  return {
    getSupportedNetworks,
    networksArray,
    networksForSelect,
    errorMessage: confirmCreateAssetForUserErrorMessage,
  };
};
