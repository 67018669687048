import { useCallback, useState } from 'react';

import { useFormik } from 'formik';

import { OuterLink, Input, Button, Typography } from '@libs/components';
import { screenSizes, theme } from '@libs/theme';

import { landingUrl, TwoFactorAuthType } from 'src/constants';
import {
  useDimensions,
  useModal,
  useThemeMode,
  useTranslation,
} from 'src/hooks';
import {
  CompleteRegistration,
  PasswordInput,
  PasswordStrengthIndicator,
} from 'src/components';
import { registerFormValidation } from 'src/validations';
import { FacebookIcon, GoogleIcon } from 'src/assets';
import { getEmailOrPhone2FaType } from 'src/helpers';

import {
  Form,
  ButtonWrapper,
  InputContainer,
  PasswordInputContainer,
  Line,
  TextAndLineWrapper,
  Option,
  SocialPlatformOptions,
  RegisterOptionWrapper,
  StyledTypography,
} from '../styles';

import { useRegister, DEFAULT_REGISTER_VALUES } from '../hooks/useRegister';

type RegisterFormProps = {
  setHasBanner: (val: boolean) => void;
  hasSubmitted: boolean;
  setHasSubmitted: (val: boolean) => void;
};

const RegisterForm: React.FC<RegisterFormProps> = ({
  setHasBanner,
  hasSubmitted,
  setHasSubmitted,
}) => {
  const { width } = useDimensions();
  const { t, selectedLanguageCode } = useTranslation();
  const { selectedTheme } = useThemeMode();

  const [type, setType] =
    useState<Exclude<TwoFactorAuthType, TwoFactorAuthType.GOOGLE>>();

  // const isCampaignUser = params.get('isCampaignUser');

  const isMobiledDevice = width < screenSizes.tabletL;

  const { Dialog, openModal, closeModal } = useModal(undefined);

  const handleButtonClick = () => {
    if (isMobiledDevice) {
      openModal();
    } else {
      setHasSubmitted(true);
      setHasBanner(false);
    }
  };

  const handleCloseIconClick = () => {
    resetForm();

    if (isMobiledDevice) {
      closeModal();
    } else {
      setHasSubmitted(false);
      setHasBanner(true);
    }
  };

  const onSendCodesSucess = () => {
    setType(getEmailOrPhone2FaType(values.emailOrPhone));
    handleButtonClick();
  };

  const {
    handleAuthComplete,
    handleFormSubmit,
    errorMessage,
    loading,
    emailOrPhoneInputErrorMessage,
    refererCodeInputErrorMessage,
    clearSendCodeErrorMessage,
    referrerCode,
  } = useRegister(onSendCodesSucess);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: DEFAULT_REGISTER_VALUES,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (vals) => {
      handleFormSubmit(vals);
    },
    validationSchema: registerFormValidation,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearSendCodeErrorMessage();
    handleChange(e);
    setFieldError(e.currentTarget.name, '');
  };

  const additionalRouteQuery = `?selectedLangCode=${selectedLanguageCode}&selectedTheme=${selectedTheme}`;

  const title =
    type === TwoFactorAuthType.SMS
      ? 'register_phone_verification'
      : 'register_email_verification';

  const onResend = useCallback(
    () => handleFormSubmit(values),
    [handleFormSubmit, values]
  );

  if (type && hasSubmitted && !isMobiledDevice)
    return (
      <CompleteRegistration
        type={type}
        authAddress={values.emailOrPhone}
        title={t(title)}
        onCloseIconClick={handleCloseIconClick}
        handleAuthComplete={handleAuthComplete}
        onResend={onResend}
        errorMessage={errorMessage}
        loading={loading}
      />
    );

  return (
    <Form onSubmit={handleSubmit}>
      <Dialog title={t(title)}>
        {type && (
          <CompleteRegistration
            type={type}
            authAddress={values.emailOrPhone}
            onCloseIconClick={handleCloseIconClick}
            handleAuthComplete={handleAuthComplete}
            errorMessage={errorMessage}
            onResend={onResend}
            loading={loading}
          />
        )}
      </Dialog>
      <InputContainer>
        <Input
          name="emailOrPhone"
          value={values.emailOrPhone}
          onChange={handleInputChange}
          label={t('register_phone_or_email')}
          placeholder={t('register_phone_or_email')}
          errorMessage={
            t(errors.emailOrPhone) || t(emailOrPhoneInputErrorMessage)
          }
          testId="emailOrPhone"
          errorTestId="emailOrPhone-error"
        />
      </InputContainer>
      <PasswordInputContainer>
        <PasswordInput
          name="password"
          type="password"
          value={values.password}
          onChange={handleInputChange}
          placeholder={t('register_password_minimum_symbols')}
          label={t('register_password_minimum_symbols')}
          errorMessage={t(errors.password)}
          testId="password"
          errorTestId="password-error"
        />
        <PasswordStrengthIndicator password={values.password} />
      </PasswordInputContainer>

      <InputContainer isLast>
        <Input
          name="referrerCode"
          onChange={handleInputChange}
          disabled={!!referrerCode}
          value={values.referrerCode}
          label={t('register_referral_code')}
          placeholder={t('register_referral_code')}
          errorMessage={
            t(errors.referrerCode) || t(refererCodeInputErrorMessage)
          }
          testId="referrerCode"
          errorTestId="referrerCode-error"
        />
      </InputContainer>
      <StyledTypography type="caption" fontFamily="primaryMedium">
        {t('register_terms_and_conditions_part_one')}{' '}
        <OuterLink
          to={`${landingUrl}/legal/terms-and-conditions${additionalRouteQuery}`}
        >
          <Typography
            color={theme.colors.interactive.brandColor}
            fontFamily="primary"
            type={'caption'}
          >
            {t('register_terms_and_conditions_part_two')}{' '}
          </Typography>
        </OuterLink>{' '}
        {t('register_terms_and_conditions_part_three')}{' '}
        <OuterLink
          to={`${landingUrl}/legal/privacy-policy${additionalRouteQuery}`}
        >
          <Typography
            color={theme.colors.interactive.brandColor}
            fontFamily="primary"
            type={'caption'}
          >
            {t('register_terms_and_conditions_part_four')}
          </Typography>
        </OuterLink>
      </StyledTypography>

      <ButtonWrapper>
        <Button
          type="submit"
          title={t('common_continue')}
          // isLoading={loading}
        />
      </ButtonWrapper>
      {/* NOTE hidden until we have api for registering with social platforms */}
      {false && (
        <>
          <TextAndLineWrapper>
            <Line />
            <Option type="bodyButton" fontFamily="primaryMedium">
              {t('common_or')}
            </Option>
            <Line />
          </TextAndLineWrapper>
          <SocialPlatformOptions>
            <RegisterOptionWrapper>
              <GoogleIcon />
              <Typography type="bodyButton" fontFamily="primaryBold">
                {t('register_google')}
              </Typography>
            </RegisterOptionWrapper>
            <RegisterOptionWrapper>
              <FacebookIcon />
              <Typography type="bodyButton" fontFamily="primaryBold">
                {t('register_facebook')}
              </Typography>
            </RegisterOptionWrapper>
          </SocialPlatformOptions>
        </>
      )}
    </Form>
  );
};

export default RegisterForm;
