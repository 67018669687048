import { FC, useMemo } from 'react';

import {
  TwoFaAuth as NativeTwoFaAuth,
  TwoFaAuthProps as NativeTwoFaAuthProps,
} from '@libs/components';
import { TwoFactorAuthType } from '@libs/types';

import { useAuth, useTranslation } from 'src/hooks';

export type TwoFaAuthProps = Omit<
  NativeTwoFaAuthProps,
  'counterText' | 'sendCodeText' | 'warningText'
>;

export const TwoFaAuth: FC<TwoFaAuthProps> = ({
  type,
  description,
  ...rest
}) => {
  const { decodeToken } = useAuth();

  const { t } = useTranslation();

  const desc = useMemo(() => {
    if (description) return description;

    const token = decodeToken();

    if (type === TwoFactorAuthType.GOOGLE) {
      return t('two_fa_google_desc');
    } else if (type === TwoFactorAuthType.EMAIL) {
      return t('verify_email_address');
    } else
      return t('two_fa_sms_desc', {
        lastDigits: token?.phone || '',
      });
  }, [t, description, type, decodeToken]);

  return (
    <NativeTwoFaAuth
      type={type}
      warningText={t('two_fa_check_spam')}
      counterText={t('register_resend_code')}
      sendCodeText={t('register_resend')}
      description={desc}
      {...rest}
    />
  );
};

export default TwoFaAuth;
