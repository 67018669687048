import { useEffect, useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import {
  Input,
  Button,
  ErrorMessage,
  RESEND_CODE_TIME,
  TWO_FA_INPUTS_COUNT,
  Typography,
} from '@libs/components';

import { useCounter, useTranslation } from 'src/hooks';
import { CodeInputs } from 'src/components';
import { TwoFactorAuthType } from 'src/constants';

import {
  Form,
  InputContainer,
  ActionWrapper,
  CodeWrapper,
  Description,
  ErrorWrapper,
  CustomDescription,
  CheckSpamWrapper,
} from '../styles';

type GenericForm<T> = {
  email: T;
};
type EditEmailFormProps = {
  values: GenericForm<string>;
  touched: FormikTouched<GenericForm<boolean>>;
  formErrors: FormikErrors<GenericForm<string>>;
  onCancel?: () => void;
  isCodeSent?: boolean;
  code: string;
  isSameEmail?: boolean;
  countDown: number;
  emailToDisplay: string;
  isButtonActive: boolean;
  isValid: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent) => void;
  sendCodeHandler: () => void;
  setCode: (code: string) => void;
  clearCode: (code: string) => void;
  errorMessage?: string;
  error: string;
  handleBlur: () => void;
  handleFocus: () => void;
  initialValue: string;
  shouldRender: boolean;
  handleEmailChange: (valCode: string) => void;
  resendHandler: () => void;
};

const EditEmailForm: React.FC<EditEmailFormProps> = ({
  isCodeSent,
  code,
  formErrors,
  touched,
  isSameEmail,
  countDown,
  emailToDisplay,
  isValid,
  handleSubmit,
  isButtonActive,
  values,
  handleChange,
  sendCodeHandler,
  setCode,
  clearCode,
  errorMessage,
  error,
  handleBlur,
  handleFocus,
  shouldRender,
  handleEmailChange,
  resendHandler,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [shouldShowWarningText, setShoulShowWarningText] = useState(false);

  const isActionDisabled =
    !!errorMessage || !isCodeSent || code.length !== TWO_FA_INPUTS_COUNT;

  const emailformErrors = touched.email ? t(formErrors.email) : '';
  const emailErrorMessage = isSameEmail
    ? t('error_new_mail_must_be_different')
    : emailformErrors;

  const emailButtonText = isCodeSent ? `${countDown}` : t('input_button');

  const description = t('edit_email_before_code_desc');

  const descriptionSecond = t('edit_email_after_code_desc', {
    email: emailToDisplay,
  });

  const customSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!!code && isValid) {
      handleSubmit(e);
    }
  };

  const {
    countDown: resendCountDown,
    counterInterval,
    isCounterRunning: isResendCounterRunning,
  } = useCounter(RESEND_CODE_TIME);

  const phoneEmailButtonWithText = isResendCounterRunning
    ? t('register_resend_code', { seconds: resendCountDown })
    : t('register_resend');

  const handleChangeEmail = (code: string) => {
    handleEmailChange(code);
  };

  const handleResendCode = () => {
    resendHandler();
    counterInterval();
  };

  const sendCode = () => {
    sendCodeHandler();
    setShoulShowWarningText(true);
  };

  useEffect(() => {
    if (initialValue) {
      counterInterval();
    }
  }, [counterInterval, initialValue]);

  const onComplete = shouldRender ? handleChangeEmail : setCode;

  return (
    <Form onSubmit={customSubmitHandler}>
      <Description description={description} />
      <InputContainer>
        {!shouldRender && (
          <Input
            name="email"
            sendCode={emailButtonText}
            valid={isButtonActive}
            value={values.email}
            onChange={handleChange}
            onClick={sendCode}
            placeholder={t('edit_email_email')}
            errorMessage={emailErrorMessage || error}
            onBlur={handleBlur}
            onFocus={handleFocus}
            disabled={isCodeSent}
            testId="changeEmailInput"
            errorTestId="email-error"
          />
        )}
      </InputContainer>
      {isCodeSent && <Description description={descriptionSecond} />}
      {shouldRender && (
        <CustomDescription
          description={t('email_change_force_input_info_second', {
            emailAddress: emailToDisplay,
          })}
        />
      )}
      {shouldShowWarningText && (
        <CheckSpamWrapper>
          <Typography type="subHeadline" fontFamily="primaryBold">
            {t('two_fa_check_spam')}
          </Typography>
        </CheckSpamWrapper>
      )}
      {isCodeSent && (
        <CodeWrapper>
          <CodeInputs
            onComplete={onComplete}
            onCodeDelete={clearCode}
            type={TwoFactorAuthType.GOOGLE}
          />
        </CodeWrapper>
      )}
      <ErrorWrapper>
        <ErrorMessage
          errorMessage={t(errorMessage)}
          errorTestId="changeEmailError"
        />
      </ErrorWrapper>

      <ActionWrapper>
        {!shouldRender ? (
          <Button
            type="submit"
            title={t('common_save')}
            disabled={isActionDisabled}
            testId="submitButton"
          />
        ) : (
          <Button
            onClick={handleResendCode}
            title={phoneEmailButtonWithText}
            disabled={isResendCounterRunning}
          />
        )}
      </ActionWrapper>
    </Form>
  );
};
export default EditEmailForm;
