import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type EditEmailArgs = {
  email: string;
  code: string;
};

export type EditEmailResponse = {
  changeEmail: DefaultApiResponse<{ accessToken: string }>;
};
export const editEmailMutation: TypedDocumentNode<
  EditEmailResponse,
  DefaultArg<EditEmailArgs>
> = gql`
  mutation EditEmail($record: UpdateEmailRecord!) {
    changeEmail(record: $record) {
      accessToken
    }
  }
`;
