import { gql, TypedDocumentNode } from '@apollo/client';
import { BonusHistoryOriginEnum } from '@libs/backend-common';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

type GetBonusHistoryArgs = DefaultArg<{
  assetCode?: string;
}>;

type BonusHistory = {
  amount: number;
  origin: BonusHistoryOriginEnum;
  bonusBalance: {
    asset: {
      code: string;
    };
    amount: number;
  };
  updatedAt: Date;
};

type GetBonusHistoryResponse = DefaultApiResponse<{
  getBonusHistory: BonusHistory[];
}>;

export const getBonusHistoryQuery: TypedDocumentNode<
  GetBonusHistoryResponse,
  GetBonusHistoryArgs
> = gql`
  query GetBonusHistory($record: GetBonusHistoryRecord!) {
    getBonusHistory(record: $record) {
      amount
      origin
      updatedAt
      bonusBalance {
        amount
        asset {
          code
        }
      }
    }
  }
`;
