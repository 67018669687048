import { useCallback, useState } from 'react';
import { useFormik } from 'formik';

import { ApolloError, useMutation } from '@apollo/client';

import { sendEmailVerificationCodeMutation } from '@libs/graphql';

import {
  generateQrCodeMutation,
  GenerateQrCodeResponse,
  turnOnGoogle2FAMutation,
  turnOffGoogle2FAMutation,
  GoogleDeactivateTwoFaResponse,
  GoogleActiveTwoFaResponse,
  sendSmsVerificationCodeMutation,
} from 'src/gql';
import { useApiResponse, useAuth } from 'src/hooks';
import { googleTwoFaValidation } from 'src/validations';

type ActivateGoogleInitialValues = {
  smsTwoFactorCode?: string;
  twoFactorCode: string;
  emailTwoFaCode?: string;
};

export const useGoogleTwoFa = (
  twoFaStatus?: boolean,
  smsCounterInterval?: () => void,
  emailCounterInterval?: () => void
) => {
  const { login } = useAuth();

  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } =
    useFormik<ActivateGoogleInitialValues>({
      initialValues: {
        smsTwoFactorCode: undefined,
        twoFactorCode: '',
        emailTwoFaCode: undefined,
      },
      onSubmit: () => {
        handleGoogleTwoFa();
      },
      validationSchema: googleTwoFaValidation,
    });

  const [getQrCode, setGetQrCode] = useState<string>();
  const [twoFaActiveStatus, setTwoFaActiveStatus] = useState<boolean>();
  const [twoFaDeactiveStatus, setTwoFaDeactiveStatus] = useState<boolean>();
  const [twoFactorSecret, setTwoFactorSecret] = useState<string>();
  const handleGetQrCodeSuccess = useCallback((data: GenerateQrCodeResponse) => {
    const qrCode = data?.generateQrCode.qrCode;
    const secret = data?.generateQrCode.secret;
    if (qrCode && secret) {
      setGetQrCode(qrCode);
      setTwoFactorSecret(secret);
    }
  }, []);
  const handleGoogleActiveTwoFaSuccess = useCallback(
    (data: GoogleActiveTwoFaResponse) => {
      if (data.turnOnGoogle2FA) {
        login(data.turnOnGoogle2FA.accessToken);
        setTwoFaActiveStatus(true);
      }
    },
    [login]
  );
  const handleGoogleDeactivateTwoFaSuccess = useCallback(
    (data: GoogleDeactivateTwoFaResponse) => {
      if (data.turnOffGoogle2FA) {
        login(data.turnOffGoogle2FA.accessToken);

        setTwoFaDeactiveStatus(true);
      }
    },
    [login]
  );
  const handleSendSmsCodeSuccess = useCallback(() => {
    smsCounterInterval?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendEmailCodeSuccess = useCallback(() => {
    emailCounterInterval?.();
  }, [emailCounterInterval]);

  const handleGoogleTwoFa = () => {
    if (values.twoFactorCode) {
      const payload = {
        variables: {
          record: {
            smsTwoFactorCode: values.smsTwoFactorCode,
            twoFactorCode: values.twoFactorCode,
            emailTwoFactorCode: values.emailTwoFaCode,
          },
        },
      };
      twoFaStatus
        ? handleDeactivateTwoFa(payload)
        : handleActivateTwoFa(payload);
    }
  };
  const [handleGetQrCode, { data: getQrCodeData, error: getQrCodeError }] =
    useMutation(generateQrCodeMutation);
  const [handleSendSmsCode, sendSmsRes] = useMutation(
    sendSmsVerificationCodeMutation
  );
  const [handleSendEmailCode, sendEmailRes] = useMutation(
    sendEmailVerificationCodeMutation
  );
  const [
    handleActivateTwoFa,
    {
      data: ActivateTwoFaData,
      error: activateTwoFaError,
      loading: activationLoading,
    },
  ] = useMutation(turnOnGoogle2FAMutation);
  const [
    handleDeactivateTwoFa,
    {
      data: DeactivateTwoFaData,
      error: deactiveTwoFaError,
      loading: deactivationLoading,
    },
  ] = useMutation(turnOffGoogle2FAMutation);

  const { errorMessage: getQrCodeErrorMessage } = useApiResponse<
    GenerateQrCodeResponse,
    ApolloError
  >({ data: getQrCodeData, errors: getQrCodeError }, handleGetQrCodeSuccess);
  const { errorMessage: activateTwoFaErrorMessage, loading: isActivating } =
    useApiResponse<GoogleActiveTwoFaResponse, ApolloError>(
      {
        data: ActivateTwoFaData,
        errors: activateTwoFaError,
        loading: activationLoading,
      },
      handleGoogleActiveTwoFaSuccess
    );
  const { errorMessage: deactivateTwoFaErrorMessage, loading: isDeactivating } =
    useApiResponse<GoogleDeactivateTwoFaResponse, ApolloError>(
      {
        data: DeactivateTwoFaData,
        errors: deactiveTwoFaError,
        loading: deactivationLoading,
      },
      handleGoogleDeactivateTwoFaSuccess
    );
  const { errorMessage: sendSmsCodeErrorMessage } = useApiResponse(
    sendSmsRes,
    handleSendSmsCodeSuccess
  );

  const { errorMessage: sendEmailCodeErrorMessage } = useApiResponse(
    sendEmailRes,
    handleSendEmailCodeSuccess
  );

  return {
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    handleSubmit,
    getQrCode,
    twoFactorSecret,
    twoFaActiveStatus,
    twoFaDeactiveStatus,
    isToggling2Fa: isActivating || isDeactivating,
    errorMessage:
      sendSmsCodeErrorMessage ||
      sendEmailCodeErrorMessage ||
      activateTwoFaErrorMessage ||
      deactivateTwoFaErrorMessage ||
      getQrCodeErrorMessage,
    handleGetQrCode,
    handleSendSmsCode,
    handleSendEmailCode,
  };
};
