import { useTheme } from 'styled-components';

import { Button, Typography } from '@libs/components';

import { BlueInfoIcon } from 'src/assets';
import { useTranslation } from 'src/hooks';

import {
  ConditionTexts,
  ImageWrapper,
  InfoIcon,
  ReferralConditionsContainer,
  VerifiedUsersWrapper,
} from './styles';

type ReferralConditionsProps = {
  verifiedUsersDuringPromotion?: string;
  closeModal?: () => void;
};

const ReferralConditions: React.FC<ReferralConditionsProps> = ({
  verifiedUsersDuringPromotion,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <ReferralConditionsContainer>
      <ImageWrapper>
        <InfoIcon src={BlueInfoIcon} />
      </ImageWrapper>
      <VerifiedUsersWrapper>
        <Typography type="titleTwo" fontFamily="primaryBold">
          {verifiedUsersDuringPromotion}
        </Typography>
        <Typography
          type="bodyButton"
          fontFamily="primaryRegular"
          color={colors.text.secondary}
        >
          {t('referral_verified_user')}
        </Typography>
      </VerifiedUsersWrapper>
      <ConditionTexts>
        <Typography type="subHeadline" fontFamily="primaryRegular">
          {t('referral_promotion_first_descr')}
        </Typography>
        <Typography type="subHeadline" fontFamily="primaryRegular">
          {t('referral_promotion_second_descr')}
        </Typography>
        <Typography type="subHeadline" fontFamily="primaryRegular">
          {t('referral_promotion_third_descr')}
        </Typography>
      </ConditionTexts>
      <Button
        size="md"
        title={t('common_close')}
        onClick={closeModal}
        type="button"
        buttonType="secondary"
      />
    </ReferralConditionsContainer>
  );
};

export default ReferralConditions;
