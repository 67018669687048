import { FC, useEffect } from 'react';

import { dashboardUrl } from 'src/constants';

import { useNavigate } from 'react-router-dom';

import { redirectToAppDownload } from '@libs/helpers';

type OSNavigatorProps = {
  iosLink: string;
  androidLink: string;
};

export const OSNavigator: FC<OSNavigatorProps> = ({ androidLink, iosLink }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleNavigation = () => {
      window.location.assign(dashboardUrl);
    };

    redirectToAppDownload(iosLink, androidLink, handleNavigation);
  }, [androidLink, iosLink, navigate]);

  return null;
};
