import { FC } from 'react';
import { useTheme } from 'styled-components';

import {
  FaqAccordion,
  ReferralProgram,
  ReferralProgramStep,
  Typography,
} from '@libs/components';

import { ReferralUsdtBanner, ReferralUsdtImg } from 'src/assets';
import { useTranslation } from 'src/hooks';

import {
  BlueWrapper,
  Container,
  ProgramsWrapper,
  StepsWrapper,
  QuestionsWrapper,
  DescriptionGlass,
  CustomImg,
  SeparatorLine,
} from './styles';

import {
  ENTERING_REFERAL_STEPS,
  REFERAL_FREQUENTLY_ASKED_QUESTIONS,
  REFERRAL_PROGRAM_TEXTS,
} from './texts';
import { InfoSection } from './InfoSection';

export const ReferralRuleDetails: FC = () => {
  const { colors } = useTheme();

  const { t } = useTranslation();

  return (
    <Container>
      <BlueWrapper>
        <DescriptionGlass>
          <Typography type="subHeadline" color={colors.text.interactive}>
            {t('referral_rule_details_first_desc')}
          </Typography>
          <SeparatorLine />
          <Typography type="subHeadline" color={colors.text.interactive}>
            {t('referral_rule_details_second_desc')}
          </Typography>
          <CustomImg src={ReferralUsdtBanner} />
        </DescriptionGlass>
      </BlueWrapper>
      <InfoSection title={t('referral_programs')}>
        <ProgramsWrapper>
          {REFERRAL_PROGRAM_TEXTS.map(
            ({ title, amount, amountDesc, desc, secondDesc }, index) => (
              <ReferralProgram
                ReferralUsdtImg={ReferralUsdtImg}
                title={t(title)}
                amountDesc={t(amountDesc)}
                desc={t(desc)}
                amount={amount}
                key={index}
                getText={t('referral_get')}
                secondDesc={t(secondDesc)}
              />
            )
          )}
        </ProgramsWrapper>
      </InfoSection>
      <InfoSection title={t('referral_how_to_enter_program')}>
        <StepsWrapper>
          {ENTERING_REFERAL_STEPS.map(({ desc }, index) => (
            <ReferralProgramStep desc={t(desc)} index={index + 1} key={index} />
          ))}
        </StepsWrapper>
      </InfoSection>
      <InfoSection title={t('referral_programs')} shouldHavePadding={false}>
        <QuestionsWrapper>
          {REFERAL_FREQUENTLY_ASKED_QUESTIONS.map(
            ({ question, answer }, index) => {
              return (
                <FaqAccordion
                  title={t(question)}
                  key={index}
                  description={answer.map((e) => t(e))}
                  titleSize="headline"
                  titleFont="primaryBold"
                />
              );
            }
          )}
        </QuestionsWrapper>
      </InfoSection>
    </Container>
  );
};

export default ReferralRuleDetails;
