import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse } from '@libs/graphql';

export type BonusTotal = {
  balanceByExchange: number;
  balanceByVerification: number;
  totalBonusBalance: number;
  totalRegisteredUsers: number;
  totalVerifiedUsers: number;
  totalClaimedBonus: number;
  verificationContributorUsers?: string;
};

export type GetBonusTotalRes = {
  getBonusTotals: BonusTotal;
};

export const getBonusTotalQuery: TypedDocumentNode<
  DefaultApiResponse<GetBonusTotalRes>
> = gql`
  query GetBonusTotals {
    getBonusTotals {
      balanceByExchange
      balanceByVerification
      totalBonusBalance
      totalRegisteredUsers
      totalVerifiedUsers
      totalClaimedBonus
      verificationContributorUsers
    }
  }
`;
