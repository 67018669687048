import { useQuery } from '@apollo/client';
import { useTheme } from 'styled-components';
import { useCallback, useMemo, useState } from 'react';

import { TransactionStatusType, TransactionType } from '@libs/types';
import { formatDate, useApiResponse } from '@libs/hooks';
import { Typography, useStatusInfo } from '@libs/components';
import { formatCryptoAmount } from '@libs/helpers';

import { TableCell } from 'src/components';
import { useTranslation } from 'src/hooks';
import { TRANSACTION_LABELS, USDT_PRICE } from 'src/constants';
import { TransactionAppliedFilters } from 'src/types';
import { getAlternativeAssetLabel } from 'src/helpers';
import { getRecentTransactionsQuery, GetRecentTransactionsRes } from 'src/gql';

interface UserDataType {
  data: Record<string, JSX.Element>[];
  mobileData: Record<string, JSX.Element>[];
  mobileOneAssetData: Record<string, JSX.Element>[];
}

export const useTransactionsTable = ({
  skip = 0,
  limit,
  appliedFilters,
  onRowClick,
  isOneAssetTransactions,
}: {
  skip?: number;
  limit: number;
  appliedFilters?: TransactionAppliedFilters;
  onRowClick?: (n: number) => void;
  isOneAssetTransactions?: boolean;
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const { getStatusData } = useStatusInfo();

  const onTransactionSuccess = (res: GetRecentTransactionsRes) => {
    if (res?.getRecentTransactions) {
      setDataIsLoaded(true);
    }
  };

  const filterdStatuses = useMemo(() => {
    return appliedFilters?.statuses.reduce((acc, status) => {
      if (status === TransactionStatusType.PENDING) {
        acc.push(
          TransactionStatusType.PENDING,
          TransactionStatusType.DRAFT,
          TransactionStatusType.PENDING_MANUAL_REVIEW,
          TransactionStatusType.PROCESSING
        );
      } else {
        acc.push(status);
      }
      return acc;
    }, [] as TransactionStatusType[]);
  }, [appliedFilters]);

  const { data: transactionsData, loading: transactionIsLoading } = useQuery(
    getRecentTransactionsQuery,
    {
      variables: {
        record: {
          skip,
          limit,
          ...appliedFilters,
          statuses: filterdStatuses,
        },
      },
      fetchPolicy: 'network-only',
    }
  );

  const { response: transactions, loading } = useApiResponse(
    {
      data: transactionsData,
      loading: transactionIsLoading,
    },
    onTransactionSuccess
  );

  const columns = useMemo(() => {
    return [
      ...(!isOneAssetTransactions
        ? [
            {
              Header: t('table_coin'),
              accessor: 'coin',
            },
          ]
        : []),
      { Header: t('table_date'), accessor: 'date' },
      { Header: t('table_type'), accessor: 'type' },
      { Header: t('table_amount'), accessor: 'amount' },
      { Header: t('table_fee'), accessor: 'fee' },
      { Header: t('table_operation_id'), accessor: 'operationId' },
      { Header: t('table_status'), accessor: 'status' },
    ];
  }, [isOneAssetTransactions, t]);

  const mobileColumns = useMemo(
    () => [
      { Header: t('table_assets'), accessor: 'asset' },
      { Header: t('table_date'), accessor: 'date' },
      { Header: t('table_price'), accessor: 'price' },
    ],
    [t]
  );

  const mobileOneAssetColumns = useMemo(
    () => [
      { Header: t('table_date'), accessor: 'date' },
      { Header: t('table_amount'), accessor: 'amount' },
      { Header: t('table_status'), accessor: 'status' },
    ],
    [t]
  );

  const userData: UserDataType = useMemo(() => {
    if (!transactions)
      return { data: [], mobileData: [], mobileOneAssetData: [] };

    return transactions.getRecentTransactions.reduce(
      (acc, transaction) => {
        const {
          assetCode: code,
          type,
          amount,
          fee,
          feeAssetCode,
          assetLabel,
          assetIconUrl,
        } = transaction.details;
        const date = new Date(transaction.createdAt);
        const assetCode = getAlternativeAssetLabel(code);

        const transactionStatus =
          transaction.status === TransactionStatusType.DRAFT
            ? getStatusData(TransactionStatusType.PENDING)
            : getStatusData(transaction.status);

        const feeToShow = type === TransactionType.BonusClaim ? '0' : fee;

        const feeAssetCodeToShow = getAlternativeAssetLabel(
          type === TransactionType.BonusClaim ? assetCode : feeAssetCode
        );

        const amountToShow =
          type === TransactionType.BonusClaim
            ? formatCryptoAmount(amount, USDT_PRICE, 2)
            : amount;

        acc.data.push({
          coin: (
            <TableCell
              img={assetIconUrl}
              title={assetLabel}
              description={assetCode}
            />
          ),
          date: (
            <TableCell
              title={formatDate('D MMM YYYY', date)}
              titleType="primaryMedium"
              titleSize="bodyButton"
              description={formatDate('HH:mm', date)}
            />
          ),
          type: (
            <Typography type="bodyButton" fontFamily="primaryMedium">
              {t(TRANSACTION_LABELS[type])}
            </Typography>
          ),
          amount: (
            <>
              <Typography type="bodyButton" fontFamily="primaryMedium">
                {`${amountToShow} `}
              </Typography>
              <Typography
                type="caption"
                fontFamily="primaryMedium"
                color={colors.text.secondary}
              >
                {assetCode}
              </Typography>
            </>
          ),

          fee: (
            <Typography type="bodyButton" fontFamily="primaryMedium">
              {feeToShow}{' '}
              <Typography
                type="caption"
                fontFamily="primaryMedium"
                color={colors.text.secondary}
              >
                {feeAssetCodeToShow}
              </Typography>
            </Typography>
          ),
          operationId: (
            <Typography type="bodyButton" fontFamily="primaryMedium">
              {transaction.id}
            </Typography>
          ),
          status: (
            <Typography
              type="bodyButton"
              fontFamily="primaryMedium"
              color={transactionStatus.colors[0]}
            >
              {t(transactionStatus.text)}
            </Typography>
          ),
        });

        acc.mobileData.push({
          asset: (
            <TableCell
              img={assetIconUrl}
              title={assetLabel}
              description={assetCode}
            />
          ),
          date: (
            <TableCell
              // title={TransactionType[type as TransactionType]}
              title={t(TRANSACTION_LABELS[type])}
              description={formatDate('D MMM, YYYY', date)}
            />
          ),
          price: (
            <TableCell
              title={`${amountToShow} ${assetCode}`}
              justifyToLeft={false}
              description={t(transactionStatus.text)}
              color={transactionStatus.colors[0]}
            />
          ),
        });

        acc.mobileOneAssetData.push({
          date: (
            <TableCell
              title={formatDate('D MMM YYYY', date)}
              titleType="primaryMedium"
              description={formatDate('HH:mm', date)}
            />
          ),
          amount: (
            <>
              <Typography type="bodyButton" fontFamily="primaryMedium">
                {`${amountToShow} `}
              </Typography>
              <Typography
                type="caption"
                fontFamily="primaryMedium"
                color={colors.text.secondary}
              >
                {assetCode}
              </Typography>
            </>
          ),
          status: (
            <TableCell
              title={t(TRANSACTION_LABELS[type])}
              justifyToLeft={false}
              description={t(transactionStatus.text)}
              color={transactionStatus.colors[0]}
            />
          ),
        });

        return acc;
      },
      { data: [], mobileData: [], mobileOneAssetData: [] } as UserDataType
    );
  }, [transactions, t, getStatusData, colors]);

  const handleRowClick = useCallback(
    (index: number) => {
      onRowClick?.(transactions?.getRecentTransactions?.[index]?.id || 0);
    },
    [onRowClick, transactions]
  );

  return {
    columns,
    data: userData.data,
    mobileColumns,
    mobileOneAssetColumns,
    mobileOneAssetData: userData.mobileOneAssetData,
    handleRowClick,
    mobileData: userData.mobileData,
    dataIsLoaded,
    loading,
  };
};
