/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType, lazy } from 'react';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (
  componentImport: () => Promise<{ default: ComponentType<any> }>
) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  }) as Promise<{ default: ComponentType<any> }>;
};

const KycPage = lazy(() => lazyRetry(() => import('./kyc/KycPage')));
const TicketPage = lazy(() => lazyRetry(() => import('./ticket/TicketPage')));
const TradePage = lazy(() => lazyRetry(() => import('./trade/TradePage')));
const WalletPage = lazy(() => lazyRetry(() => import('./wallet/WalletPage')));
const StakingPage = lazy(() =>
  lazyRetry(() => import('./staking/StakingPage'))
);
const WithdrawalPage = lazy(() =>
  lazyRetry(() => import('./withdrawal/WithdrawalPage'))
);
const ReferralsPage = lazy(() =>
  lazyRetry(() => import('./referrals/ReferralsPage'))
);
const FeedbackPage = lazy(() =>
  lazyRetry(() => import('./feedback/FeedbackPage'))
);
const BogPaymentsMobile = lazy(() =>
  lazyRetry(() => import('./bog-payments-mobile/BogPaymentsMobile'))
);
const DepositPage = lazy(() =>
  lazyRetry(() => import('./deposit-crypto/DepositPage'))
);
const DashboardContent = lazy(() =>
  lazyRetry(() => import('./dashboard/DashboardContent'))
);
const MobileMorePage = lazy(() =>
  lazyRetry(() => import('./mobile-more-page/MobileMorePage'))
);
const TransactionsPage = lazy(() =>
  lazyRetry(() => import('./transactions/TransactionsPage'))
);
const NotificationsPage = lazy(() =>
  lazyRetry(() => import('./notifications/NotificationsPage'))
);
const StakingDetailsPage = lazy(() =>
  lazyRetry(() => import('./staking-details/StakingDetailsPage'))
);
const StakingCalculatorPage = lazy(() =>
  lazyRetry(() => import('./staking-calculator/StakingCalculatorPage'))
);
const CardOperationResult = lazy(() =>
  lazyRetry(() => import('./card-operation-result/CardOperationResult'))
);
const AccountInformationPage = lazy(() =>
  lazyRetry(() => import('./account-information/AccountInformationPage'))
);
const SupportInfoPage = lazy(() =>
  lazyRetry(() => import('./support-info-page/SupportInfoPage'))
);
const DashboardFeesPage = lazy(() =>
  lazyRetry(() => import('./fees-page/DashboardFeesPage'))
);
const StakingCalculationPreviewPage = lazy(() =>
  lazyRetry(() => import('./staking-calculator/StakingCalculationPreviewPage'))
);
const EarlyRedemption = lazy(() =>
  lazyRetry(
    () => import('./staking/components/early-redemption/EarlyRedemption')
  )
);

export {
  TicketPage,
  KycPage,
  TradePage,
  WithdrawalPage,
  WalletPage,
  DepositPage,
  StakingPage,
  FeedbackPage,
  ReferralsPage,
  MobileMorePage,
  EarlyRedemption,
  DashboardContent,
  TransactionsPage,
  NotificationsPage,
  BogPaymentsMobile,
  StakingDetailsPage,
  CardOperationResult,
  StakingCalculatorPage,
  AccountInformationPage,
  SupportInfoPage,
  StakingCalculationPreviewPage,
  DashboardFeesPage,
};
