import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  width: 100%;

  ${({ theme }) => {
    return theme.media.laptop`  
      justify-content: center;
    `;
  }};
`;

export const JustifiedContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100%;
  max-width: 110rem;

  padding-bottom: 11rem;

  ${({ theme }) => theme.media.laptop`
    justify-content: center;

    padding: 2rem 1rem 5rem;
  `};
`;
