import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { JurisdictionsEnum } from '@libs/backend-common';
import { TwoFactorAuthPage } from '@libs/components';
import { TwoFactorAuthType } from '@libs/types';

import {
  bitnetLogo,
  bitnetLogoSmall,
  CrexoneLogo,
  CrexoneLogoBlack,
  CrexoneMobileLogo,
} from 'src/assets';
import {
  useTranslation,
  useJurisdictionDetector,
  useTwoFaDetection,
  useRenderObjectByTheme,
} from 'src/hooks';

const TwoFaPage = () => {
  const { t, changeLanguage, selectedLanguageCode } = useTranslation();
  const { search } = useLocation();
  const { twoFAType, decodedToken } = useTwoFaDetection(search);
  const navigate = useNavigate();

  const { currentJurisdiction } = useJurisdictionDetector();
  const IS_GLOBAL_JURISDICTION = currentJurisdiction === JurisdictionsEnum.UA;

  const twoFaData = useMemo(() => {
    if (twoFAType === TwoFactorAuthType.EMAIL) {
      return {
        title: t('two_fa_email_title'),
        desc: t('verify_email_address', {
          email: decodedToken?.email || '',
        }),
      };
    } else if (twoFAType === TwoFactorAuthType.SMS) {
      return {
        title: t('two_fa_sms_title'),
        desc: t('two_fa_sms_desc', {
          lastDigits: decodedToken?.phone || '',
        }),
      };
    }
    return { title: t('two_fa_google_title'), desc: t('two_fa_google_desc') };
  }, [decodedToken?.email, decodedToken?.phone, t, twoFAType]);

  const handleOnCancel = useCallback(() => {
    navigate('/login', { replace: true });
  }, [navigate]);

  const crexonelogo = useRenderObjectByTheme(CrexoneLogoBlack, CrexoneLogo);

  return (
    <TwoFactorAuthPage
      jurisdictionId={currentJurisdiction}
      changeLanguage={changeLanguage}
      selectedLanguage={selectedLanguageCode}
      title={twoFaData.title}
      description={twoFaData.desc}
      type={twoFAType}
      logo={IS_GLOBAL_JURISDICTION ? crexonelogo : bitnetLogo}
      mobileLogo={IS_GLOBAL_JURISDICTION ? CrexoneMobileLogo : bitnetLogoSmall}
      onCancel={handleOnCancel}
      headerButtonTitle={t('login_register_text')}
      incorrectCodeErrMessage={t('two_fa_invalid_code_error')}
      warningText={t('two_fa_check_spam')}
      counterText={t('register_resend_code')}
      sendCodeText={t('register_resend')}
    />
  );
};
export default TwoFaPage;
