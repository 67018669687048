import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { useTheme } from 'styled-components';

import { BonusHistoryOriginEnum } from '@libs/backend-common';
import { TableCell, Typography } from '@libs/components';
import { formatDate, useApiResponse } from '@libs/hooks';

import { useTranslation } from 'src/hooks';
import { getBonusHistoryQuery } from 'src/gql';

import { CurrencyType } from '@libs/types';
import { formatAmountWithSymbol } from '@libs/helpers';

const TRANSLATION_KEYS = {
  [BonusHistoryOriginEnum.USER_VERIFICATION]:
    'referral_invite_friend_reward_title',
  [BonusHistoryOriginEnum.EXCHANGE]: 'referral_trade_friend_reward_title',
  [BonusHistoryOriginEnum.CLAIM]: 'referral_history_claimed',
};

export const useReferralHistoryTable = () => {
  const { t } = useTranslation();

  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      { Header: '', accessor: 'operation' },
      { Header: '', accessor: 'amount' },
    ],
    []
  );

  const bonusHistory = useQuery(getBonusHistoryQuery, {
    variables: {
      record: {
        assetCode: CurrencyType.USDT,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { loading, response } = useApiResponse(bonusHistory);

  const data = useMemo(() => {
    if (!response?.getBonusHistory) return [];

    return response.getBonusHistory?.map(
      ({
        origin,
        updatedAt,
        amount,
        bonusBalance: {
          asset: { code },
        },
      }) => {
        const textColor =
          origin === BonusHistoryOriginEnum.CLAIM
            ? colors.text.default
            : colors.text.success;

        const formatedDate = formatDate('DD.MMM.YYYY', updatedAt);

        return {
          operation: (
            <TableCell
              title={formatedDate}
              titleColor={colors.text.secondary}
              color={colors.text.default}
              description={t(TRANSLATION_KEYS[origin])}
              titleSize="caption"
              descType="subHeadline"
            />
          ),
          amount: (
            <Typography
              color={textColor}
              type="subHeadline"
              fontFamily="primaryBold"
            >
              {formatAmountWithSymbol({
                amount,
                symbol: code,
                reverse: true,
              })}
            </Typography>
          ),
        };
      }
    );
  }, [response?.getBonusHistory, t, colors]);

  return { columns, data, loading };
};
